
export function date({date = new Date(), utc = true, startOfMonth = false, startOfDay = false, startOfHour = false, addedMonth = 0, addedDay = 0, addedSec = 0} = {}) {
  utc = utc ? 'UTC' : '';
  let month = date[`get${utc}Month`]();
  let day = date[`get${utc}Date`]();
  let hour = date[`get${utc}Hours`]();
  let min = date[`get${utc}Minutes`]();
  let sec = date[`get${utc}Seconds`]();
  let ms = date[`get${utc}Milliseconds`]();
  if (startOfMonth || startOfDay || startOfHour) {
    ms = 0;
    sec = 0;
    min = 0;
    if (startOfMonth || startOfDay) {
      hour = 0;
      if (startOfMonth) {
        day = 1;
      }
    }
  }
  const dt = utc ? new Date(Date.UTC(date.getUTCFullYear(), month, day, hour, min, sec, ms)) : new Date(date.getFullYear(), month, day, hour, min, sec, ms);
  if (addedMonth) dt[`set${utc}Month`](dt[`get${utc}Month`]() + addedMonth);
  if (addedDay) dt[`set${utc}Date`](dt[`get${utc}Date`]() + addedDay);
  if (addedSec) dt[`set${utc}Seconds`](dt[`get${utc}Seconds`]() + addedSec);

  return dt;
}

export function dateIdWithDate({date, hasYear = true, hasMonth = true, hasDay = true, hasHour = false, hasMin = false, hasSec = false, hasMs = false} = {}) {
  const dt = date || new Date();
  const toStr = (val, min = 2) => {
    val = `${val}`;
    while (val.length < min) val = '0' + val;
    return val;
  }
  const arr = [];
  if (hasYear) arr.push(toStr(dt.getUTCFullYear()));
  if (hasMonth) arr.push(toStr(dt.getUTCMonth() + 1));
  if (hasDay) arr.push(toStr(dt.getUTCDate()));
  if (hasHour) arr.push(toStr(dt.getUTCHours()));
  if (hasMin) arr.push(toStr(dt.getUTCMinutes()));
  if (hasSec) arr.push(toStr(dt.getUTCSeconds()));
  if (hasMs) arr.push(toStr(dt.getUTCMilliseconds(), 3));
  return arr.join('');
}

export function dtAddedMonth(month, date) {
  date = date ?? new Date();
  date.setMonth(date.getMonth() + month);
  return date;
}

export function dtAddedDay(day, date) {
  date = date ?? new Date();
  date.setDate(date.getDate() + day);
  return date;
}

export function dtDirectToUtc(date) { return dtFromUtcStr(dtToStr(date)) }

export function dtToStr(date) {
  const dt = date || new Date();
  let res = '' + dt.getFullYear();
  res += ' ' + dt.getMonth();
  res += ' ' + dt.getDate();

  res += ' ' + dt.getHours();
  res += ' ' + dt.getMinutes();
  res += ' ' + dt.getSeconds();

  res += ' ' + dt.getMilliseconds();
  return res;
}

export function dtToUtcStr(date) {
  const dt = date || new Date();
  let res = '' + dt.getUTCFullYear();
  res += ' ' + dt.getUTCMonth();
  res += ' ' + dt.getUTCDate();

  res += ' ' + dt.getUTCHours();
  res += ' ' + dt.getUTCMinutes();
  res += ' ' + dt.getUTCSeconds();

  res += ' ' + dt.getUTCMilliseconds();
  return res;
}

export function dtInNumsFromUtcStr(dtUtcStr) {
  if (!dtUtcStr) return null;
  const arr = dtUtcStr.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = parseInt(arr[i]);
  }
  while (arr.length < 7) {
    arr.push(arr.length === 2 ? 1 : 0);
  }
  return arr;
}

export function dtFromUtcStr(str) {
  const nums = dtInNumsFromUtcStr(str);
  if (!nums) return null;
  return new Date(Date.UTC(...nums));
}

export function dtDaysDiff(date1, date2) {
  if (!date1 || !date2) return 0;
  const ms = date2.getTime() - date1.getTime();
  return ms / (1000 * 3600 * 24);
}

export function dtForeverPromo() {
  return dtAddedDay(1000 * 365);
}

//

export function dtFromFirestoreTimestamp(obj) {
  return obj ? new Date(obj._seconds * 1000 + obj._nanoseconds/1000000) : null;
}

export function dtToHumanStr(date, timeZone) {
  if (!date || !date.toLocaleString) return date;
  if (!timeZone) timeZone = 'Asia/Bishkek';
  timeZone = timeZone?.trim();
  const extra = !timeZone ? null : {timeZone};
  let str;
  try {
    str = date.toLocaleString('en-GB', extra);
  } catch(e) {}
  if (!str) str = date.toLocaleString('en-GB');
  if (timeZone) str += ` (${timeZone})`;
  return str;
}




