import { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Dropdown, Row } from "react-bootstrap";
import DatePicker from 'react-date-picker';
import Dinero from 'dinero.js'
import * as helper from './../core/helper';
import * as modelp from "../data/report/payment/model";
import * as modelu from "../data/report/usage/model";
import dsp from "./../data/report/payment/payment-events";
import dsu from "./../data/report/usage/usages";
import { GeneralData } from "../data/general";
import { RefreshIconBtn, NextIconBtn, PrevIconBtn } from "../comps/icon-btns";
import { DashCardCommon } from "./report/comps";
import { makeStyles } from "@material-ui/core";
import { getMrrDataForChart } from "./report/comps/mrr-chart-view";

const defDate = () => helper.date({utc: false, addedDay: 0, startOfDay: true});

const cache = {
  date: defDate(),
  refreshCount: 0,
  loadKey: '',
  lastLoadedDate: new Date(),
}

function DashboardPage() {
  const [date, setDate] = useState(cache.date);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(cache.refreshCount);
  const anyLoaded = useRef(false);
  const classes = useStyles();
  const [data, setData] = useState(new MyData());

  useEffect(() => {
    cache.date = date;
    cache.refreshCount = refresh;
  }, [date, refresh]);

  useEffect(() => {
    const key = `${date} [refresh]${refresh}`;
    let force = key.substring(key.indexOf('[refresh]')) !== cache.loadKey.substring(cache.loadKey.indexOf('[refresh]'));
    if ((new Date()).getTime() - cache.lastLoadedDate.getTime() > 5 * 60 * 1000) force = true;

    if (anyLoaded.current && !force && (key === cache.loadKey || loading)) return;
    cache.lastLoadedDate = new Date();
    cache.loadKey = key;
    anyLoaded.current = true;
    setLoading(true);

    (async () => {
      const myData = new MyData();

      // ---------------------
      // billing
      let from = helper.dtDirectToUtc(helper.date({date, startOfMonth: true, utc: false}));
      let to = helper.dtDirectToUtc(helper.date({date, startOfMonth: true, addedMonth: 1, addedSec: -1, utc: false}));
      await dsp.month.fetchData({from: helper.dtDirectToUtc(helper.dtAddedDay(-367, new Date(from))), to, dataType: modelp.DataType.month, force});

      myData.startGroup('Billing');
      
      for (let i = 0; i < modelp.CurrType.ALL.length; i++) {
        const currType = modelp.CurrType.ALL[i];
        const mrrs = await getMrrDataForChart({currType, from, to});
        const mrr = mrrs[mrrs.length-1];
        const din = Dinero({currency: currType.name, amount: Math.round((mrr?.value ?? 0) * 100)});
        myData.add({
          name: 'MRR—' + currType.displayName, 
          sub: 'Monthly',
          value: din.toFormat('0,0.00'),
          test: GeneralData.appEnv().name === modelp.EnvType.test.name,
        });
      }

      for (let i = 0; i < modelp.CurrType.ALL.length; i++) {
        const currType = modelp.CurrType.ALL[i];
        const result = (await dsp.month.getTotalItems({from, to, currType}))[0];
        const sums = result?.sumItem(modelp.PurchType.all, modelp.MonthType.all);
        const din = Dinero({currency: currType.name, amount: Math.round((sums?.positive ?? 0) * 100)});
        myData.add({
          name: 'Payments—' + currType.displayName,
          sub: 'Monthly',
          value: din.toFormat('0,0.00'),
          test: GeneralData.appEnv().name === modelp.EnvType.test.name,
          keyval: [
            ['Count', sums?.countPositive ?? 0],
          ],
        });
      }

      // ---------------------
      // usage
      from = helper.dtDirectToUtc(helper.date({date, startOfDay: true, utc: false}));
      to = helper.dtDirectToUtc(helper.date({date, startOfDay: true, addedDay: 1, addedSec: -1, utc: false}));
      await dsu.dash.fetchData({from, to, force});
      const items = dsu.dash.getItems();
      items?.forEach(item => {
        myData.startGroup('Business');
        myData.add({name: 'All', sub: 'Business', value: (item.business.valueForKey('all') ?? 0)});
        myData.add({name: 'New', sub: 'Business', value: (item.business.valueForKey('newb') ?? 0)});
        myData.add({name: 'Subscribed', sub: 'Business', value: (item.business.valueForKey('sub') ?? 0)});
        myData.add({name: 'Promoted', sub: 'Business', value: (item.business.valueForKey('prom') ?? 0)});
        
        myData.startGroup('Visits');
        myData.add({name: 'POS', sub: 'Unique visit',
          value: (item.module.valueForKey('POS') ?? 0),
          keyval: [
            ['Opened', item.opened.valueForKey('POS') ?? 0],
          ],
        });
        myData.add({name: 'Back Office', sub: 'Unique visit',
          value: (item.module.valueForKey('BOF') ?? 0),
          keyval: [
            ['Opened', item.opened.valueForKey('BOF') ?? 0],
          ],
        });
        myData.add({name: 'KDS', sub: 'Unique visit',
          value: (item.module.valueForKey('KDS') ?? 0),
          keyval: [
            ['Opened', item.opened.valueForKey('KDS') ?? 0],
          ],
        });
        myData.add({name: 'CDS', sub: 'Unique visit',
          value: (item.module.valueForKey('CDS') ?? 0),
          keyval: [
            ['Opened', item.opened.valueForKey('CDS') ?? 0],
          ],
        });
        
        myData.startGroup('App');
        myData.add({name: 'Mac', sub: 'App', value: (item.osFlat.valueForKey('mac app') ?? 0)});
        myData.add({name: 'iOS', sub: 'App', value: (item.osFlat.valueForKey('ios app') ?? 0)});
        myData.add({name: 'Android', sub: 'App', value: (item.osFlat.valueForKey('android app') ?? 0)});
        myData.add({name: 'Web', sub: 'App', value: (item.webOrApp.valueForKey('web') ?? 0)});

        myData.startGroup('Resources');
        myData.add({name: 'Location', sub: 'Resources', value: (item.resouces.valueForKey('loc') ?? 0)});
        myData.add({name: 'Employee', sub: 'Resources', value: (item.resouces.valueForKey('emp') ?? 0)});
        myData.add({name: 'POS', sub: 'Resources', value: (item.resouces.valueForKey('pos') ?? 0)});
        myData.add({name: 'Printer', sub: 'Resources', value: (item.resouces.valueForKey('prn') ?? 0)});
        myData.add({name: 'Product', sub: 'Resources',
          value: (item.resouces.valueForKey('prod') ?? 0),
          keyval: [
            ['Ingredient', item.resouces.valueForKey('ingr') ?? 0],
          ],
        });
      });
      
      setData(myData);
      setLoading(false);
    })();
  }, [date, loading, refresh]);

  return (
    <>
    <Container>
      <Row>
        <Col lg={12}>
          <div disabled={loading} className="filterControls">
            <span>
              <DatePicker value={date} maxDate={new Date()} onChange={newDate => { setDate(newDate ?? defDate()); }} />
            </span>
            <span>
              <PrevIconBtn onClick={() => setDate(helper.date({utc: false, date, addedDay: -1}))} />
              <NextIconBtn onClick={() => setDate(helper.date({utc: false, date, addedDay: 1}))} disabled={date >= helper.date({utc: false, startOfDay: true, addedSec: -1})} />
            </span>
            <span>
              <RefreshIconBtn size="small" busy={loading} onRefresh={() => setRefresh(refresh+1)} />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {data?.groups?.map((groupId, index) =>
            <div key={groupId}>
              {index > 0 && <hr/>}
              <div className={classes.items}>
                {data.groupsByid[groupId].map(obj =>
                  <DashCardCommon key={obj.id} title={obj.name} subtitle={obj.sub} value={obj.value} keyval={obj.keyval} test={obj.test} />
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default DashboardPage;

const useStyles = makeStyles(theme => ({
  items: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(204px, 204px) )',
    gridAutoRows: 'minmax(204px, auto)',
    columnGap: 16,
    rowGap: 16,
    paddingTop: 8,
  },
}));

class MyData {
  constructor() {
    this.arr = [{id: '', name: '', sub: '', value: 0, test: false}];
    this.arr.pop();
    this.byid = {};
    this.groups = [];
    this.groupsByid = {};
  }

  startGroup(id) {
    this.groupsByid[id] = [];
    this.groups.push(id);
    this.currGroupId = id;
  }

  add({name, sub, value, keyval, test}) {
    const id = name + sub;
    if (!this.byid[id]) {
      this.byid[id] = {id, ...arguments[0]};
      this.arr.push(this.byid[id]);

      if (this.currGroupId) {
        this.groupsByid[this.currGroupId].push(this.byid[id]);
      }
    }
    else {
      this.byid[id].value += value;
    }
  }
}



