import { CurrType, EnvType } from "./report/payment/model";
import payments from "./report/payment/payment-events";
import usages from "./report/usage/usages";

const localCacheVersion = 'v2';

export class GeneralData {
  static chartColors = ['#e41a1c', '#8884d8', '#82ca9d', '#984ea3', '#4daf4a', '#377eb8', '#a65628', '#f781bf'];
  static monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  static monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

  static get currencies() {
    return CurrType.ALL.map(item => item.displayName);
  }

  static appEnv({shouldReturnFallback = true} = {}) {
    const env = EnvType.prod.withName(localStorage.getItem('activeEnv'));
    if (!env && shouldReturnFallback) return EnvType.prod;
    return env;
  }
  static setAppEnv(envname) {
    envname = EnvType.prod.withName(envname)?.name ?? 'prod';
    localStorage.setItem('activeEnv', envname);
  }

  static cleanLocalCache() {
    const donotClear = ['usertoken', 'activeEnv', 'localCacheVersion'];
    for (let i = 0; i < donotClear.length; i++) donotClear[i] = [donotClear[i], localStorage.getItem(donotClear[i])];
    localStorage.clear();
    payments.cleanCache();
    usages.cleanCache();
    for (let i = 0; i < donotClear.length; i++) localStorage.setItem(donotClear[i][0], donotClear[i][1]);
  }
}

if (localStorage.getItem('localCacheVersion') !== localCacheVersion) {
  GeneralData.cleanLocalCache();
  localStorage.setItem('localCacheVersion', localCacheVersion);
}

if (GeneralData.chartColors.length < 50) {
  const len = GeneralData.chartColors.length;
  let i = 0;
  while (GeneralData.chartColors.length < 50) {
    GeneralData.chartColors.push(GeneralData.chartColors[i++]);
  }
}



