import * as model from "./model";
import * as helper from './../../../core/helper';
import { PaymentEventsDaily, PaymentEventsHourly, PaymentEventsMonthly } from "./payment-events-services";
import { GeneralData } from "../../general";

export class PEventTransformer {
  #lastDataLen = 0;
  constructor() {
    this.data = [];
    this.onUpdate = () => console.log('onUpdate should be overriden');
  }
  /**
   * @param {model.ItemResultGroup} res
   */
  addResult(res) { }
  resetResult(shouldNotify = true) {
    this.data = [];
    if (shouldNotify) this.notify();
    else this.#lastDataLen = this.data.length;
  }
  dispose() { this.onUpdate = null; }
  notify() {
    if (this.#lastDataLen !== this.data?.length) this.data = [...(this.data ?? [])];
    this.#lastDataLen = this.data.length;
    this.onUpdate && this.onUpdate();
  }
}

class PaymentEvents {
  #monthlyServices = new Map([[2022, new PaymentEventsMonthly(2022)]]);
  #dailyServices = new Map([['minthlyid', new PaymentEventsDaily()]]);
  #hourlyServices = new Map([['monthlyid.dailyid', new PaymentEventsHourly()]]);

  #lastFrom = new Date();
  #lastTo = new Date();
  #lastDataType = new model.DataType('dummy');

  get lastDataType() { return this.#lastDataType }

  isInDateRange(date, from, to) { return date && date >= from && date < to; }

  async fetchData({from = new Date(), to = new Date(), dataType = new model.DataType(), force = false}) {
    if (!force)
    if (
      this.#lastFrom?.getTime() === from?.getTime() &&
      this.#lastTo?.getTime() === to?.getTime() && 
      this.#lastDataType?.inSec <= dataType?.inSec) return;
    this.#lastFrom = from;
    this.#lastTo = to;
    this.#lastDataType = dataType;
    await this.#getItems({from, to, dataType, shouldFetch: true});
  }

  /**
   * @callback onAddCb
   * @param {model.ItemResultGroup} item
   */
  /**
   * 
   * @param {{env: model.EnvType, dataType: model.DataType, from: Date, to: Date, purchType: model.PurchType, monthType: model.MonthType, currType: model.CurrType, onAdd: onAddCb}}
   * @return {[model.ItemResultGroup]} 
   */
  async getTotalItems({env, dataType, from, to, purchType, monthType, currType = new model.CurrType(), onAdd, fillGaps = true} = {}) {
    if (!env) env = GeneralData.appEnv();
    if (!dataType) dataType = this.lastDataType;
    if (!from) from = this.#lastFrom;
    if (!to) to = this.#lastTo;

    const newItemResGroup = (prev, date, children) => new model.ItemResultGroup(prev, {date, children, dataType, env, from, to, monthType, currType});
    const dateToMin = (date) => {
      const arr = helper.dtToUtcStr(date).split(' ');
      const len = arr.length;
      arr[len-1] = '0'; // ms
      arr[len-2] = '0'; // sec
      arr[len-3] = '0'; // min
      if (dataType?.inSec >= model.DataType.day.inSec) arr[len-4] = '0'; // hours
      if (dataType?.inSec >= model.DataType.month.inSec) arr[len-5] = '1'; // days
      return helper.dtFromUtcStr(arr.join(' '));
    }

    let res = [];
    const itemResultsByUtcMin = {};
    const roots = (await this.#getItems({from, to, dataType, fetchData: false})) ?? [];
    for (let i = 0; i < roots.length; i++) {
      const parent = roots[i];
      if (!from || !to || !this.isInDateRange(parent.crd, from, to)) continue;

      const totals = parent?.getTotalsOfEnv(env ?? undefined);
      const items = totals?.getItems({purchType, monthType, currType}) ?? [];
      const prev = res.length > 0 ? res[res.length-1] : null;
      const resGroup = newItemResGroup(
        prev,
        parent?.crd,
        items.map(item => new model.ItemResult(item, {parent, totals, monthType: item.monthType, purchType: item.purchType})),
      );
      res.push(resGroup);
      !fillGaps && onAdd && onAdd(resGroup);

      const utcKey = helper.dtToUtcStr(dateToMin(parent?.crd));
      if (!itemResultsByUtcMin[utcKey]) itemResultsByUtcMin[utcKey] = [];
      itemResultsByUtcMin[utcKey].push(...resGroup.children);
    }

    if (fillGaps) {
      const newRes = [];
      const date = dateToMin(from);
      while (date < to) {
        const prev = newRes.length > 0 ? newRes[newRes.length-1] : null;
        const utcKey = helper.dtToUtcStr(date);
        const children = itemResultsByUtcMin[utcKey] ?? [];
        const resGroup = newItemResGroup(prev, new Date(date), children);
        newRes.push(resGroup);
        onAdd && onAdd(resGroup);

        if (dataType?.name === model.DataType.month.name) date.setMonth(date.getMonth()+1);
        else if (dataType?.name === model.DataType.day.name) date.setDate(date.getDate()+1);
        else date.setHours(date.getHours()+1);
      }
      res = newRes;
    }

    return res;
  }

  //

  async #getItems({from = new Date(), to = new Date(), dataType = model.DataType.hour, shouldFetch = false}) {
    let items = [new model.AbstractItem()];
    items = await this.#loadMonthly(from, to, {shouldFetch});
    if (dataType.name === model.DataType.day.name || dataType.name === model.DataType.hour.name) {
      items = await this.#loadDaily(items, {shouldFetch});
      if (dataType.name === model.DataType.hour.name) {
        items = await this.#loadHourly(items, {shouldFetch});
      }
    }
    items = items.filter(item => this.isInDateRange(item.crd, from, to));
    return items;
  }

  #fetchedDays = new Set();
  async #loadHourly(dailyArr = [], {shouldFetch = false} = {}) {
    let res = [new model.Hourly()];
    res.pop();

    const loadPromises = [];
    for (let i = 0; i < dailyArr.length; i++) {
      const daily = dailyArr[i];
      const service = this.#getHourlyService(daily);
      if (service) {
        if (shouldFetch && !this.#fetchedDays.has(service.cacheId)) {
          loadPromises.push(new Promise(async (resolve) => resolve(await service.load())));
          if (i < dailyArr.length-1) this.#fetchedDays.add(service.cacheId);
        } else {
          res.push(...(service.data ?? []));
        }
      }
    }

    if (loadPromises.length > 0) {
      res = (await Promise.all(loadPromises)).flat();
    }

    return res;
  }

  #fetchedMonths = new Set();
  async #loadDaily(monthlyArr = [], {shouldFetch = false} = {}) {
    let res = [new model.Daily()];
    res.pop();
    
    const loadPromises = [];
    for (let i = 0; i < monthlyArr.length; i++) {
      const monthly = monthlyArr[i];
      const service = this.#getDailyService(monthly);
      if (service) {
        if (shouldFetch && !this.#fetchedMonths.has(service.cacheId)) {
          loadPromises.push(new Promise(async (resolve) => resolve(await service.load())));
          if (i < monthlyArr.length-1) this.#fetchedMonths.add(service.cacheId);
        } else {
          res.push(...(service.data ?? []));
        }
      }
    }

    if (loadPromises.length > 0) {
      res = (await Promise.all(loadPromises)).flat();
    }

    return res;
  }

  #fetchedYears = new Set();
  async #loadMonthly(from = new Date(), to = new Date(), {shouldFetch = false} = {}) {
    let res = [new model.Monthly()];
    res.pop();

    const loadPromises = [];
    const nowUtcYear = (new Date()).getUTCFullYear();
    let date = new Date(from);
    const seen = new Set();
    while (date.getUTCFullYear() <= to.getUTCFullYear()) {
      const service = this.#getMonthlyService(date.getUTCFullYear());
      if (!seen.has(service.utcYear)) {
        seen.add(service.utcYear);
        if (shouldFetch && !this.#fetchedYears.has(service.utcYear)) {
          loadPromises.push(new Promise(async (resolve) => resolve(await service.load())));
          if (service.utcYear !== nowUtcYear) this.#fetchedYears.add(service.utcYear);
        } else {
          res.push(...(service.data ?? []));
        }
      }
      date.setUTCFullYear(date.getUTCFullYear() + 1);
    }

    if (loadPromises.length > 0) {
      res = (await Promise.all(loadPromises)).flat();
    }

    return res;
  }

  #getMonthlyService(utcYear = 2022) {
    this.#createMonthlyService(utcYear);
    return this.#monthlyServices.get(utcYear);
  }

  #createMonthlyService(utcYear = 2022) {
    if (this.#monthlyServices.has(utcYear)) return;
    const service = new PaymentEventsMonthly(utcYear);
    if (utcYear !== service.utcYear) this.#createMonthlyService(service.utcYear);
    else this.#monthlyServices.set(utcYear, service);
  }

  #getDailyService(monthly) {
    const id = monthly?.id;
    if (id && !this.#dailyServices.has(id)) this.#dailyServices.set(id, new PaymentEventsDaily(monthly));
    return this.#dailyServices.get(id);
  }

  #getHourlyService(daily) {
    if (!daily?.parent?.id || !daily?.id) return null;
    const id = `${daily.parent.id}.${daily.id}`;
    if (id && !this.#hourlyServices.has(id)) this.#hourlyServices.set(id, new PaymentEventsHourly(daily));
    return this.#hourlyServices.get(id);
  }
}

const _instances = {
  def: new PaymentEvents(),
  month: new PaymentEvents(),
  allInstances: function() { return [this.def, this.month] },

  cleanCache: function() {
    this.def = new PaymentEvents();
    this.month = new PaymentEvents();
  }
}
export default _instances;


