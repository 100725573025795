import api from "../../../api/api";
import * as helper from '../../../core/helper';
import { UsageItemId, UsageItem } from "./model";

export class UsagesMonthLoader {
  #loading = false;

  constructor(yyyymm) {
    this.yyyymm = yyyymm;
    this._cache = new helper.Cache('usage');
  }

  get cacheId() { return this._cache.id }

  #waitForLoad() {
    return new Promise(resolve => {
      const inter = setInterval(() => {
        if (!this.#loading) {
          clearInterval(inter);
          resolve();
        }
      }, 50);
    });
  }

  async load({to} = {}) {
    if (this.#loading) {
      await this.#waitForLoad();
      return;
    }
    this.#loading = true;

    try {
      const fromCahe = this._cache.read(this.yyyymm);
      if (fromCahe && typeof fromCahe === 'object') {
        this._data = fromCahe;
      } else {
        let shouldLoad = true;
        if (to && this._data) {
          const toId = helper.dateIdWithDate({date: to, hasYear: true, hasMonth: true, hasDay: false});
          if (toId === this.yyyymm) {
            const hasDayInData = (day) => this._data['d' + day] || this._data['d0' + day];
            if (hasDayInData(to.getUTCDate()) && hasDayInData(to.getUTCDate() + 1)) {
              shouldLoad = false;
            }
          }
        }

        if (shouldLoad) {
          const data = await this.#load();
          this._data = data ?? {};
          this._parsed = null;
        }
      }
    } catch(e) {console.error(e)}

    this.#loading = false;
  }

  async #load() {
    const path = 'bof/reports/usage/months/' + this.yyyymm;

    const resp = await api.inst.get(path);
    const loadedData = resp?.data;
    if (this.yyyymm !== helper.dateIdWithDate({date: helper.dtDirectToUtc(new Date()), hasYear: true, hasMonth: true, hasDay: false})) {
      this._cache.write(this.yyyymm, loadedData);
    }
    return loadedData;
  }

  /**
   * @return {[UsageItem]}
   */
  get data() {
    if (!this._parsed && this._data) {
      this._parsed = [];
      const monthId = new UsageItemId(this.yyyymm);
      const days = monthId.numberOfDaysInMonth;
      for (let i = 1; i <= days; i++) {
        const dd = `${i < 10 ? '0' : ''}${i}`;
        const id = new UsageItemId(this.yyyymm, dd);
        const json = this._data['d' + dd];
        const item = new UsageItem(id, json);
        this._parsed.push(item);
      }
    }
    return this._parsed;
  }
}






