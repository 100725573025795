import { useEffect, useRef, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrmbs, LoadingComp, ModalCentered } from "../../comps/comps";
import { RefreshIconBtn, NextIconBtn, PrevIconBtn } from "../../comps/icon-btns";
import custData from "./../../data/customers";
import { BillingEvent } from "./../../data/cust-billing-events";
import * as helper from "../../core/helper";

function CustomerBillingEventsPage(props) {
  const email = props.email
  const src = custData.billingEventsForEmail(email);
  const page = useRef(1);
  const inited = useRef(false);
  const [data, setData] = useState(src.getPage());
  const [isLoading, setIsLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(null);

  useEffect(() => {
    if (!inited.current) {
      loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async (p = 1) => {
    page.current = p;
    setIsLoading(true);
    const res = await src.loadList(p);
    setData(res);
    setIsLoading(false);
  }

  if (isLoading) return <LoadingComp />;

  return (
    <div>
      <ModalCentered show={showDetail} onHide={() => setShowDetail(null)} headerTitle={'Billing event details'}>
        <Details data={showDetail}/>
      </ModalCentered>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div></div>
        <div>
          <PrevIconBtn
            disabled={page.current <= 1} 
            onClick={() => loadData(page.current-1)}
            />
          <NextIconBtn
            disabled={page.current > src.loadedPage() || (page.current === src.loadedPage() && src.allPagesLoaded())}
            onClick={() => loadData(page.current+1)}
            />
          <RefreshIconBtn busy={isLoading} onRefresh={() => {
            page.current = 1;
            src.reset();
            loadData();
          }}/>
        </div>
      </div>
      <Table striped bordered>
          <thead>
            <tr>
                <th>OK</th>
                <th>Type</th>
                <th>Message</th>
                <th>Date</th>
                <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item =>
              <tr key={item.key}>
                <td style={{color: item.ok ? 'green':  'red'}}>{item.ok ? 'true' : 'false'}</td>
                <td>{item.type} {item.test ? <Badge bg="secondary">test</Badge> : ''}</td>
                <td style={{color: item.ok ? 'green':  'red'}}>
                  {item.msg}
                  {item.executedBofUser &&
                    <>
                      <br/>
                      <mark style={{color: 'black'}}>{item.executedBofUser ? ' Executed BOF user' : ''}</mark>
                    </>
                    }
                </td>
                <td>{item.crdStr}</td>
                <td>
                  <Button variant="link" size="sm" onClick={() => setShowDetail(item)}>
                    Show Detail
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
    </div>
  );
}

export default CustomerBillingEventsPage;

function Details({data}) {
  /**
   * @returns {BillingEvent}
   */
  const getItem = () => data;
  const item = getItem();
  if (!item) return <div></div>;
  const detail = normDetailObj(item.detail);
  
  return (
    <div>
      <div style={{color: item.ok ? 'green':  'red'}}>{item?.msg}</div>
      <div>{item?.type}</div>
      <br/>
      <pre>{JSON.stringify(detail, null, 2)}</pre>
    </div>
  );
}

function normDetailObj(obj) {
  if (!obj) return obj;
  obj = structuredClone(obj);
  const normVal = (item) => {
    if (!item) return item;
    if (typeof item === 'object') {
      handleItem(item);
    } else {
      if (typeof item === 'string') {
        // dt
        {
          const arr = item.split(' ');
          if (arr.length === 7 && arr[0].length === 4 && !arr.find(val => val.length > 4 || val.length === 0)) {
            item = helper.dtToHumanStr(helper.dtFromUtcStr(item));
          }
        };
      }
    }
    return item;
  }
  const handleItem = (item) => {
    if (!item) return;
    if (typeof item === 'object') {
      if (Array.isArray(item)) {
        for (let i = 0; i < item.length; i++) {
          item[i] = normVal(item[i]);
        }
      } else {
        const keys = Object.keys(item);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          item[key] = normVal(item[key]);
        }
      }
    }
  }
  handleItem(obj);
  return obj;
}


