import axios from 'axios';

class Api {
  #baseURL = window.location.href.includes('local') ? 'http://localhost:8085/api' : 'https://bapi.altospos.com/api';
  #headers = {};
  #token;

  get baseURL() { return this.#baseURL }

  constructor() {
    this.addToken(localStorage.getItem('usertoken'));
  }

  #createInstance() {
    this.inst = this.instWithBaseURL(this.#baseURL);
  }

  addHeader(key, val) {
    if (val === null) delete this.#headers[key];
    else this.#headers[key] = val;
    this.#createInstance();
  }

  addToken(val) {
    if (this.#token === val) return;
    if (!val) localStorage.removeItem('usertoken');
    else localStorage.setItem('usertoken', val);
    this.#token = val;
    this.addHeader('authorization', 'Bearer ' + this.#token);
  }

  hasToken() {
    return this.#token && this.#token !== null && this.#token !== 'null';
  }

  instWithBaseURL(baseURL) {
    const inst = axios.create({
      baseURL: baseURL,
      timeout: 2000,
      headers: this.#headers,
    });
    inst.interceptors.response.use((res) => {
      return res;
    }, (err) => {
      const status = err?.response?.status;
      if (status === 403 || status === 401) {
        window.location.href = "/login";
      }
    });
    return inst;
  }
}

const api = new Api();

export default api;



