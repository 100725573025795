import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../hooks/useauth";
import { Grid, CircularProgress } from "@material-ui/core";
import { createRef, useEffect } from "react";

function AuthPage() {
  const navigate = useNavigate();
  const { loading, login, logout } = useAuth();
  const recaptchaRef = createRef();

  useEffect(() => {
    logout();
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    if (loading) return;
    const { username, pass } = document.forms[0];
    const captcha = recaptchaRef.current.getValue();
    if (!captcha) return alert('Verify captcha!');
    login(username.value, pass.value, captcha).then((ok) => {
      if (ok) navigate("/");
      else alert('Error!');
    });
  };
  
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <div className="form">
          <form onSubmit={handleLogin}>
            <div className="form-item">
              {/* <label>Username </label> */}
              <input type="text" name="username" required />
            </div>
            <div className="form-item">
              {/* <label>Password </label> */}
              <input type="password" name="pass" required />
            </div>
            <div className="form-item">
              {loading ? <CircularProgress size="1rem" /> : <input type="submit" />}
            </div>
          </form>
        </div>
        <div><br/></div>
        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdkhggiAAAAAK3KJyS6_VvQgsoZM5CJqVL3zMOh"
          />
        </div>
      </Grid>   
    </Grid>
  );
};

export default AuthPage;
