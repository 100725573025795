import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { GeneralData } from "../data/general";
import { EnvType } from "../data/report/payment/model";
import { Link, useNavigate } from "react-router-dom";
import Revcat from "./../data/revcat";

function SettingsPage() {
  const nav = useNavigate();
  const [testMode, setTestMode] = useState(GeneralData.appEnv()?.name === EnvType.test.name);

  return (
    <div>
      <Form>
        <Form.Check 
          type="switch"
          id="custom-switch"
          label={testMode ? 'Display Test Environment Data (reports, revenues...)' : 'Display Production Environment Data (reports, revenues...)'}
          checked={testMode}
          onChange={() => {
            GeneralData.setAppEnv(testMode ? EnvType.prod.name : EnvType.test.name);
            setTestMode(!testMode);
          }}
        />
      </Form>
      <br/>
      <Link to={`/logs`}>Logs</Link>
      <br/><br/>
      <hr/>
      <div className="filterControls">
        <span>
          <Button variant="secondary" onClick={() => window.confirm('Logout?') ? nav('/login') : null}>Logout</Button>
        </span>
        <span>
          <Button variant="secondary" onClick={() => {
            if (!window.confirm('Clear cache?')) return;
            GeneralData.cleanLocalCache();
            alert('Done!');
          }}>Clear cache</Button>
        </span>
        <span>
          <Button variant="secondary" onClick={async () => {
            if (!window.location.hostname.includes('localhost')) {
              const pin = window.prompt('Pin');
              if (pin !== '1234') return;
            }
            const hook = window.prompt('Hook');
            let hookJson;
            try { hookJson = JSON.parse(hook); } catch (e) { alert('Invalid input!'); }
            if (!hookJson) return;
            if (await Revcat.execHook(hook)) {
              alert('Success');
            } else {
              alert('Error');
            }
          }}>Exec revcat hook</Button>
        </span>
      </div>
    </div>
  );
}

export default SettingsPage;


