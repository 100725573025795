import * as helper from '../../../core/helper';
import { UsageItemId, UsageItem } from "./model";
import { UsagesMonthLoader } from './usages-services';

export * from './transformer';

export class Usages {
  #lastFrom = new Date();
  #lastTo = new Date();
  #listeners = new Set();

  addListener(fn) { this.#listeners.add(fn); }
  removeListener(fn) { this.#listeners.delete(fn); }
  hasListener(fn) { return this.#listeners.has(fn); }

  async fetchData({from = new Date(), to = new Date(), force = false}) {
    if (!force)
      if (this.#lastFrom?.getTime() === from?.getTime() && this.#lastTo?.getTime() === to?.getTime()) return;
    this.#lastFrom = from;
    this.#lastTo = to;
    await this.#loadItems({from, to});
  }

  async #loadItems({from = new Date(), to = new Date()}) {
    const services = getMonthLoaders(from, to);
    await Promise.all(services.map(service => new Promise(async (resolve) => resolve(await service.load({to})))));
    itemsCache.clear();

    const data = this.getItems({from, to});
    let i = 0;
    this.#listeners.forEach(listener => setTimeout(() => listener(data), i++));
  }

  /**
   * 
   * @param {Date} from 
   * @param {Date} to 
   * @returns {[UsageItem]}
   */
  getItems({from, to} = {}) {
    from = from ?? this.#lastFrom;
    to = to ?? this.#lastTo;
    if (!from || !to) return [];

    const key = `${from.toString()} ${to.toString()}`;
    if (itemsCache.has(key)) return itemsCache.get(key);

    const fromyyyymmdd = toYyyymmddNum(from);
    const toyyyymmdd = toYyyymmddNum(to);

    const arr = [];
    const services = getMonthLoaders(from, to);
    for (let i = 0; i < services.length; i++) {
      const items = services[i].data ?? [];
      for (let j = 0; j < items.length; j++) {
        const item = items[j];
        if (item.id.numval >= fromyyyymmdd && item.id.numval <= toyyyymmdd) {
          arr.push(item);
        }
      }
    }

    itemsCache.set(key, arr);
    return arr;
  }
}

//
const itemsCache = new Map([['', [new UsageItem()]]]);
const monthLoaders = new Map([['', new UsagesMonthLoader()]]);
monthLoaders.clear();

const toYyyymmNum = (date) => {
  return parseInt(helper.dateIdWithDate({date, hasYear: true, hasMonth: true, hasDay: false}));
}

const toYyyymmddNum = (date) => {
  return parseInt(helper.dateIdWithDate({date, hasYear: true, hasMonth: true, hasDay: true}));
}

/**
 * 
 * @param {Date} from 
 * @param {Date} to 
 * @returns {[UsagesMonthLoader]}
 */
const getMonthLoaders = (from, to) => {
  const services = [];
  const toYYYYMM = toYyyymmNum(to);
  let currDate = from;
  let currYYYYMM = toYyyymmNum(currDate);
  while (currYYYYMM <= toYYYYMM) {
    const yyyymm = `${currYYYYMM}`;
    if (!monthLoaders.has(yyyymm)) monthLoaders.set(yyyymm, new UsagesMonthLoader(yyyymm));
    services.push(monthLoaders.get(yyyymm));
    currDate = helper.dtAddedMonth(1, new Date(currDate));
    currYYYYMM = toYyyymmNum(currDate);
  }
  return services;
}

const _instances = {
  def: new Usages(),
  dash: new Usages(),
  allInstances: function() { return [this.def, this.dash] },

  cleanCache: function() {
    this.def = new Usages();
    this.dash = new Usages();
  }
}
export default _instances;





