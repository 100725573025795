import * as React from "react";
import api from "./../api/api";

const authContext = React.createContext();

export function useAuth() {
  const [authed, setAuthed] = React.useState(api.hasToken());
  const [loading, setLoading] = React.useState(false);

  return {
    authed,
    loading,
    login(username, pass, captcha) {
      return new Promise(async (res) => {
        setLoading(true);
        let resp;
        try {
          resp = await api.inst.post('/bof/login', {username, pass, captcha});
        } catch(e) {}

        api.addToken(resp?.data?.token);
        setAuthed(api.hasToken());
        setLoading(false);
        res(api.hasToken());
      });
    },
    logout() {
      return new Promise((res) => {
        api.addToken(null);
        setAuthed(false);
        res();
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}


