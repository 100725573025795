import { Routes, Route, Navigate } from "react-router-dom";
import * as icons from "@material-ui/icons";
import { useAuth } from './../hooks/useauth';
import CustomersPage from '../pages/customer/customers-page';
import CustomerPage from "../pages/customer/customer-page";
import EntitlementsPage from "../pages/entitlements-page";
import OfferingsPage from "../pages/offerings-page";
import OfferingsDetailPage from "../pages/offerings-detail-page";
import EntitlementPage from "../pages/entitlement-page";
import DashboardPage from "../pages/dashboard-page";
import SettingsPage from "../pages/settings-page";
import LogsPage, { LogsDetailPage } from "../pages/logs-page";
import UsageReportsPage from "../pages/report/usage-reports-page";
import BillingReportsPage from "../pages/report/billing-reports-page";
import CustomersMatomoPage from "../pages/customer-matomo/customers-matomo-page";
import SubscriptionForecastPage from "../pages/subscription/sub-forecast-page";

export const NavItems = [
  {path: '/', title: 'Dashboard', icon: <icons.DashboardRounded/>},
  {path: '/customers', title: 'Customers', icon: <icons.SupervisorAccount/>},
  {path: '/matomo/customers', title: 'Customers - Matomo', icon: <icons.SupervisorAccount/>},
  {isGroup: true, title: 'Reports & Analytics'},
  {path: '/reports/subscription/forecast', title: 'Subscription forecast', icon: <icons.Subscriptions/>},
  {path: '/reports/usage', title: 'Usage', icon: <icons.DataUsageRounded/>},
  {path: '/reports/billing', title: 'Billing', icon: <icons.MonetizationOnRounded/>},
  {isGroup: true, title: 'Billing'},
  {path: '/offerings', title: 'Offerings', icon: <icons.MoneyOffRounded/>},
  {path: '/entitlements', title: 'Entitlements', icon: <icons.LockRounded/>},
  {isGroup: true, title: 'Settings'},
  {path: '/settings', title: 'Settings', icon: <icons.SettingsRounded/>},
  // {path: '/login', title: 'Logout', icon: <icons.ExitToApp/>},
];

function RequireAuth({ children }) {
  const { authed } = useAuth();
  return authed === true ? children : <Navigate to="/login" replace />;
}

function MyRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<RequireAuth><DashboardPage/></RequireAuth>} />
      <Route path="/customers" element={<RequireAuth><CustomersPage/></RequireAuth>} />
      <Route path="/customers/email/:email" element={<RequireAuth><CustomerPage/></RequireAuth>} />

      <Route path="/matomo/customers" element={<RequireAuth><CustomersMatomoPage/></RequireAuth>} />

      <Route path="/reports/subscription/forecast" element={<RequireAuth><SubscriptionForecastPage/></RequireAuth>} />
      <Route path="/reports/usage" element={<RequireAuth><UsageReportsPage/></RequireAuth>} />
      <Route path="/reports/billing" element={<RequireAuth><BillingReportsPage/></RequireAuth>} />

      <Route path="/entitlements" element={<RequireAuth><EntitlementsPage/></RequireAuth>} />
      <Route path="/entitlements/:id" element={<RequireAuth><EntitlementPage/></RequireAuth>} />
      <Route path="/offerings" element={<RequireAuth><OfferingsPage/></RequireAuth>} />
      <Route path="/offerings/:id" element={<RequireAuth><OfferingsDetailPage/></RequireAuth>} />

      <Route path="/settings" element={<RequireAuth><SettingsPage/></RequireAuth>} />
      <Route path="/logs" element={<RequireAuth><LogsPage/></RequireAuth>} />
      <Route path="/logs/:name" element={<RequireAuth><LogsDetailPage/></RequireAuth>} />
    </Routes>
  );
}

export default MyRoutes;
