import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RefreshIconBtn } from "../../comps/icon-btns";
import dataSource from "./../../data/subscription/sub-forecast";
import { ModalCentered } from "../../comps/comps";
import EntitlementPage from "../entitlement-page";

function SubscriptionForecastPage() {
  const navigation = useNavigate();
  const inited = useRef(false);
  const [customers, setCustomers] = useState(dataSource.customers);
  const [isLoading, setIsLoading] = useState(false);
  const [showEnt, setShowEnt] = useState(null);

  useEffect(() => {
    if (!inited.current) {
      loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async (p = 1) => {
    setIsLoading(true);
    const res = await dataSource.loadData();
    setCustomers(res);
    setIsLoading(false);
  }

  return (
    <div>
      <ModalCentered show={showEnt?.id} onHide={() => setShowEnt(null)} headerTitle={'Entitlement'}>
        <EntitlementPage id={showEnt?.id}/>
      </ModalCentered>
      <div className="filterControls">
        <span>
          <RefreshIconBtn busy={isLoading} onRefresh={() => {
            dataSource.reset();
            loadData();
          }}/>
        </span>
      </div>
      <Container><Row><Col>
        <Table striped bordered>
          <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Business</th>
                <th>Country</th>
                <th>Revenue</th>
                <th>Entitlement</th>
                <th>Expires</th>
                <th>Forecast</th>
            </tr>
          </thead>
          <tbody>
            {customers && customers.map((item, index) =>
              <tr key={item.id}>
                <td>{1 + index}</td>
                <td>{item.name}</td>
                <td><Link to={`/customers/email/${item.email}`}>{item.email}</Link></td>
                <td>{item.busType}</td>
                <td>{item.countryName}</td>
                <td>{item?.purchTotals?.averagesStr?.join(', ')}</td>
                <td>{item?.myEntName}</td>
                <td>{item.getMyEntExpiresInStr()}</td>
                <td style={{
                  backgroundColor: `rgba(0, 255, 0, ${dataSource.forecastOf(item)/100})`,
                  fontWeight: 400,
                  fontSize: 12 + (dataSource.forecastOf(item) * 15 / 100),
                }}>
                  {dataSource.forecastOfAsStr(item)}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col></Row></Container>
    </div>
  );
}

export default SubscriptionForecastPage;
