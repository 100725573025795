import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { Badge, Card, ListGroup } from "react-bootstrap";

export function DashCardCommon({title, subtitle, value, keyval, test}) {
  const classes = useStyles();

  const fontSize = useMemo(() => {
    const val = `${value}`.trim();
    const len = val.length;
    if (len < 6) return 48;
    if (len < 7) return 43;
    if (len < 8) return 36;
    if (len < 9) return 32;
    if (len < 10) return 28;
    if (len < 11) return 25;
    if (len < 12) return 23;
    if (len < 13) return 21;
    return Math.max(4, 19 - (len-13));
  }, [value]);
  
  return (
    <Card>
      {test && <small className={classes.tesProd}><Badge pill>Test</Badge></small>}
      <Card.Body className={classes.body}>
        <Card.Title>
          <center>
            {title}
            {subtitle && <><br/><small className={classes.subtitle}>{subtitle}</small></>}
          </center>
        </Card.Title>

        <div style={{fontSize: fontSize*1.225}}>
          {value}
        </div>
        {keyval &&
          <div className={classes.keyval}>
            {keyval.map(item =>
              <div
                key={item[0] + item[1]}
                className="d-flex justify-content-between align-items-start"
                style={{borderTop: '1px solid lightgray', paddingTop: 4, paddingBottom: 4}}
                >
                {item[0]}
                <span>{item[1]}</span>
              </div>
            )}
          </div>
        }
        {!keyval && <div></div>}
      </Card.Body>
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 'normal',
    fontSize: 16,
  },
  body: {
    display: 'grid',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    alignContent: 'space-between',
  },
  tesProd: {
    position: 'absolute',
    right: 4,
    bottom: 2,
    opacity: 0.62,
  },
  keyval: {
    fontSize: 16,
  },
}));











