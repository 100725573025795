import { IconButton } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrmbs, LoadingComp, ModalCentered } from "../../comps/comps";
import { RefreshIconBtn, NextIconBtn, PrevIconBtn } from "../../comps/icon-btns";
import custData, { Customer } from "../../data/customers";
import custMatomo from "../../data/matomo/customers-matomo";
import EntitlementPage from "../entitlement-page";
import OfferingsDetailPage from "../offerings-detail-page";
import CustomerBillingEventsPage from "./cust-billing-events-page";
import EditDefaultWebOfferings from "./edit-def-web-offerings";
import EditEnt from "./edit-ent";
import * as helper from './../../core/helper';
import EditPreferedEntitlements from "./edit-prefered-ent";

function CustomerPage() {
  const navigation = useNavigate();
  const { email } = useParams();
  const inited = useRef(false);
  const [data, setData] = useState(custData.itemForEmail(email));
  const [matomoData, setMatomoData] = useState(custMatomo.matomoBusByEmail.get(email));
  const [isLoading, setIsLoading] = useState(false);
  const [editDefWebOfferings, setEditDefWebOfferings] = useState(false);
  const [showOffering, setShowOffering] = useState();
  const [editEnt, setEditEnt] = useState(false);
  const [showEnt, setShowEnt] = useState(null);
  const [editPreferedEnt, setEditPreferedEnt] = useState(false);
  const [showBillingEvents, setShowBillingEvents] = useState(false);

  useEffect(() => {
    if (!inited.current) {
      if (data?.email !== email) loadData();
      else if (!matomoData?.email) loadMatomoBus();
    }
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    await custData.reloadCustWithEmail(email);
    setData(custData.itemForEmail(email));
    await loadMatomoBus();
  }

  const loadMatomoBus = async () => {
    setIsLoading(true);
    const matomoCust = await custMatomo.loadBusWithEmailid(helper.emailidOf(email));
    setMatomoData(matomoCust);
    setIsLoading(false);
  }

  if (isLoading) return <LoadingComp />;

  const data0 = [
    ['Entitlement',
      <>
        <IconButton aria-label="edit" size="small" onClick={() => setEditEnt(true)}><icons.Edit /></IconButton>
        {' '}
        <Button variant="link" size="sm" onClick={() => setShowEnt(data?.myEnt)}>
          {data?.myEntName}
        </Button>
      </>
    ],
    ['Entitlement expires', data?.getMyEntExpiresInStr({onlyDays: false}) ?? ''],
    ['Prefered web offerings',
      <>
        <IconButton aria-label="edit" size="small" onClick={() => setEditDefWebOfferings(true)}><icons.Edit /></IconButton>
        <Button variant="link" size="sm" onClick={() => setShowOffering(data?.preferedWebOfferingsIdFull)}>
          {data?.preferedWebOfferingsId}
          {data?.preferedWebOfferingsId === Customer.individualFixedPriceWebOfferingKey ? ' '+data.individualFixedPriceWebOffering?.priceFor1Month : ''}
          {data?.preferedWebOfferingsId === Customer.individualFixedPriceWebOfferingKey ? ' '+data.individualFixedPriceWebOffering?.curr : ''}
        </Button>
      </>
    ],
    ['Override entitlements',
      <>
        <IconButton aria-label="edit" size="small" onClick={() => setEditPreferedEnt(true)}><icons.Edit /></IconButton>
        {data?.preferedEnts?.entids?.map(id =>
          <span key={id}>
            <Button variant="link" size="sm" onClick={() => setShowEnt(data.preferedEnts.getEntWithId(id))}>
              {data.preferedEnts.getEntWithId(id)?.name()}
            </Button>
            {' '}
          </span>
        )}
      </>
    ],
    ['Billing events',
      <>
        <Button variant="link" size="sm" onClick={() => setShowBillingEvents(true)}>
          Show
        </Button>
      </>
    ],
  ];

  const deviceInfo = data?.deviceInfo;
  const resources = deviceInfo?.resources;
  const visits = deviceInfo?.dailyUniqueVisits;

  const devices = deviceInfo?.devices ?? [];
  devices.sort((a, b) => b.lastUpdateDate.getTime() - a.lastUpdateDate.getTime());

  const dataInfo0 = [
    ['id', data?.id ?? 'Not Found!'],
    ['BType', data?.busType],
    ['Name', data?.name],
    ['Email', data?.email],
    ['Country', data?.countryName],
    ['City', data?.city],
    ['Timezone', data?.timezone],
    ['First seen', data?.firstSeenStr],
    ['Last seen', data?.lastSeenStr],
    ['Total paid', <b>{data?.purchTotals?.positivesStr?.join(', ')}</b>],
    ['Total refund', data?.purchTotals?.negativesStr?.join(', ')],
  ];
  if (data?.revcatTransferAnonymId) {
    dataInfo0.push(['Revcat transfer alias', data.revcatTransferAnonymId]);
  }

  const dataInfo1 = [
    ['Location', resources?.location],
    ['POS', resources?.pos],
    ['Product', resources?.product],
    ['Ingredient', resources?.ingredient],
    ['Employee', resources?.employee],
    ['CDS', resources?.cds],
    ['KDS', resources?.kds],
    ['Printer', resources?.printer],
    ['Last updated', resources?.lastUpdateDateStr],
  ];

  return (
    <>
    <ModalCentered show={showEnt?.id} onHide={() => setShowEnt(null)} headerTitle={'Entitlement'}>
      <EntitlementPage id={showEnt?.id} cust={data}/>
    </ModalCentered>
    <ModalCentered show={showBillingEvents} onHide={() => setShowBillingEvents(false)} headerTitle={'Billing events'} size="lg">
      <CustomerBillingEventsPage email={email}/>
    </ModalCentered>
    <ModalCentered
      show={showOffering}
      onHide={() => setShowOffering(null)}
      headerTitle={'Offerings'}
      >
      <OfferingsDetailPage id={showOffering} cust={data} />
    </ModalCentered>

    <ModalCentered
      show={editDefWebOfferings}
      onHide={() => setEditDefWebOfferings(false)}
      headerTitle={`Prefered web offerings`}
      backdrop="static"
      >
      <EditDefaultWebOfferings custEmail={email} onDone={() => {
        setEditDefWebOfferings(false);
        loadData();
      }} />
    </ModalCentered>

    <ModalCentered
      show={editEnt}
      onHide={() => setEditEnt(false)}
      headerTitle={`Entitlement`}
      backdrop="static"
      >
      <EditEnt custEmail={email} onDone={() => {
        setEditEnt(false);
        loadData();
      }} />
    </ModalCentered>

    <ModalCentered
      show={editPreferedEnt}
      onHide={() => setEditPreferedEnt(false)}
      headerTitle={`Override entitlements`}
      backdrop="static"
      >
      <EditPreferedEntitlements custEmail={email} onDone={() => {
        setEditPreferedEnt(false);
        loadData();
      }} />
    </ModalCentered>

    <div disabled={isLoading}>
      <div className="filterControls">
        <span>
          <Breadcrmbs
            items={[{to: -1, title: 'Customers'}]}
            currentTitle={data?.name ?? data?.email ?? email}
          />
        </span>
        <span>
          <RefreshIconBtn busy={isLoading} onRefresh={loadData}/>
        </span>
      </div>
      <div>
        <Container>
          {!data?.id && <Row><Col>Not Found!</Col></Row> }
          {data?.id && <>
          <Row>
            <Col lg={9}>
              <Table striped bordered>
                <tbody>
                  {data0.map(val =>
                    <tr key={val[0]+'a'}>
                      <td>{val[0]}</td>
                      <td>{val[1]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered>
                <tbody>
                    <tr><td>Total daily unique visits</td><td>{visits?.allBusinessVisits}</td></tr>
                    <tr><td>Total daily device unique visits</td><td>{visits?.allDeviceVisits}</td></tr>
                    <tr><td>Today device unique visits</td><td>{visits?.todayDeviceVisits}</td></tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row><Col><br /></Col></Row>
          <Row>
            <Col>
              <Table striped bordered>
                <tbody>
                  {dataInfo0.map(val =>
                    <tr key={val[0]+'b'}>
                      <td>{val[0]}</td>
                      <td>{val[1]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered>
                <tbody>
                  {dataInfo1.map(val =>
                    <tr key={val[0]+'c'}>
                      <td>{val[0]}</td>
                      <td>{val[1]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row><Col><br /></Col></Row>
          <Row>
            <Col lg={12}><h6>Devices</h6></Col>
            <Col>
              <Table striped bordered>
                <tbody>
                  {devices.map((device, index) =>
                    <tr key={device.id+'d'}>
                      <td>{index + 1}</td>
                      <td>{device.moduleName}</td>
                      <td>{device.appType}</td>
                      <td>{device.version}</td>
                      <td>{device.size}</td>
                      <td>{device.langName}</td>
                      <td>{device.countryName}</td>
                      <td>{device.city}</td>
                      <td style={{color: (device.lastUpdateDaysAgo < 1.5 ? 'green' : (device.lastUpdateDaysAgo > 6 ? '#999999' : '#666666'))}}>
                        {device.lastUpdateDateStr}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          </>}

          <Row><Col><hr/></Col></Row>
          <Row>
            <Col lg={12}><h5>Matomo</h5></Col>
            {matomoData?.busType && <>
            <Col lg={12}>
              <Badge pill>{matomoData?.busType}</Badge>{' '}
              <Badge pill>{matomoData?.language}</Badge>{' '}
              <Badge pill>{matomoData?.country}</Badge>{' '}
              <Badge pill>Days since first visit: {matomoData?.daysSinceFirstVisit}</Badge>{' '}

              {matomoData?.resources?.hasData && <>
              <Badge pill bg="secondary">Location: {matomoData?.resources?.location}</Badge>{' '}
              <Badge pill bg="secondary">POS: {matomoData?.resources?.pos}</Badge>{' '}
              <Badge pill bg="secondary">Employee: {matomoData?.resources?.employee}</Badge>{' '}
              <Badge pill bg="secondary">Product: {matomoData?.resources?.product}</Badge>{' '}
              <Badge pill bg="secondary">Ingredient: {matomoData?.resources?.ingredient}</Badge>{' '}
              </>}
              <br/><br/>
            </Col>
            </>}
            <Col>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Lang</th>
                    <th>Country</th>
                    <th>OS</th>
                    <th>Type</th>
                    <th>Resolution</th>
                    <th>First&nbsp;Visit</th>
                    <th>Last&nbsp;Visit</th>
                    <th>Visit</th>
                    <th>Id</th>
                  </tr>
                </thead>
                <tbody>
                  {matomoData?.devices?.map((device, index) =>
                    <tr key={device.id+'md'}>
                      <td>{index + 1}</td>
                      <td>{device.language}</td>
                      <td>{device.country}</td>
                      <td>{device.osDesc}</td>
                      <td>{device.deviceTypes.joined}</td>
                      <td>{device.resolutions.joined}</td>
                      <td>{device.daysSinceFirstVisit}d. ago</td>
                      <td style={{color: (device.lastActionDaysAgo < 1.5 ? 'green' : (device.lastActionDaysAgo > 6 ? '#999999' : '#666666'))}}>
                        {device.lastActionHuman}
                      </td>
                      <td>{device.visitCount ?? '-'}</td>
                      <td><small>{device.id}</small></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    </>
  );
}

export default CustomerPage;


