import api from "./../../api/api";
import { Customer } from "../customers";

class SubscriptionForecast {
  constructor() {
    this.customers = [new Customer()];
    this.customers.pop();

    this.custForecast = new Map();
    this.custForecast.set('dummy', 0);
    this.custForecast.clear();
  }

  reset() {
    this.customers = [];
  }

  forecastOf(cust) {
    // if (cust.email == 'amparorenajoy84@gmail.com') return 99;
    return this.custForecast.get(cust?.email) ?? 0;
  }

  forecastOfAsStr(cust) {
    return `${Math.floor(this.forecastOf(cust))}%`;
  }

  async loadData({countryCode} = {}) {
    if (this.customers.length > 0) return this.customers;

    try {
      let path = '/bof/customers/potential_sub?';
      if (countryCode) {
        path += '&countryCode=' + countryCode;
      }

      const resp = await api.inst.get(path);
      resp.data?.forEach(element => {
        const cust = new Customer(element);
        this.customers.push(cust);
      });

      this.calcForecast();

      // this.customers.sort((a, b) => {
      //   return a.firstSeen.getMilliseconds() - b.firstSeen.getMilliseconds();
      // });

    } catch(e) {}
    return this.customers;
  }

  calcForecast() {
    // forecst
    const calc = new PercentCalc();
    calc.addGroup(14, 99, 'active_days');
    calc.addGroup(4, 99, 'device_visits_ratio');
    calc.addGroup(50, 99, 'prod');
    // calc.addGroup(20, 99, 'ingr');
    calc.addGroup(4, 99, 'pos');
    calc.addGroup(4, 99, 'emp');

    for (const cust of this.customers) {
      let percent = 0;

      // if (cust.hasAnyPurch) {
      //   percent = 100;
      // } else {
        const deviceInfo = cust?.deviceInfo;
        const resources = deviceInfo?.resources;
        const visits = deviceInfo?.dailyUniqueVisits;

        calc.setUserVal(cust.email, visits?.allBusinessVisits, 'active_days');
        calc.setUserVal(cust.email, visits?.allDeviceVisits/visits?.allBusinessVisits, 'device_visits_ratio');

        calc.setUserVal(cust.email, resources?.product + ((resources?.ingredient/2) || 0), 'prod');
        // calc.setUserVal(cust.email, resources?.ingredient, 'ingr');

        calc.setUserVal(cust.email, resources?.pos, 'pos');

        calc.setUserVal(cust.email, resources?.employee, 'emp');

        percent = calc.getUserPercent(cust.email);
      // }

      this.custForecast.set(cust.email, percent);
    }
  }
}

class PercentCalc {
  constructor() {
    this.groupMax = {};
    this.groupWeight = {};
    this.userGroupVal = {};
  }

  addGroup(max, weight, name) {
    this.groupMax[name] = max;
    this.groupWeight[name] = weight;
  }

  setUserVal(user, val, group) {
    if (!this.userGroupVal[user]) this.userGroupVal[user] = {};
    this.userGroupVal[user][group] = val || 0;
  }

  getUserPercent(user) {
    const bygroup = this.userGroupVal[user];
    if (!bygroup) return 0;
    let maxWeight = 0;
    const percentsWithWeight = [];

    const groupNames = Object.keys(bygroup);
    for (const groupName of groupNames) {
      const val = bygroup[groupName] || 0;
      const max = this.groupMax[groupName] || 0;
      const percent = (Math.min(max, val) * 100.0 / max) || 0;

      const weight = this.groupWeight[groupName] || 0;
      percentsWithWeight.push([percent, weight]);

      maxWeight = Math.max(maxWeight, weight);
    }

    let total = 0;
    for (const percentAndWeight of percentsWithWeight) {
      total += percentAndWeight[0] * percentAndWeight[1] / maxWeight;
    }

    return total / percentsWithWeight.length;
  }
}

const data = new SubscriptionForecast();
export default data;


