import { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as countries from "country-list";
import { Breadcrmbs, LoadingComp, ModalCentered } from "../../comps/comps";
import { RefreshIconBtn, NextIconBtn, PrevIconBtn } from "../../comps/icon-btns";
import EntitlementPage from "../entitlement-page";
import custData from "./../../data/customers";
import * as helper from './../../core/helper';

const untilDts = [
  {id: 999999, name: 'All'},
  {id: 0, name: 'Today'},
  {id: 1, name: 'Last 2 days'},
  {id: 2, name: 'Last 3 days'},
  {id: 3, name: 'Last 4 days'},
  {id: 4, name: 'Last 5 days'},
  {id: 5, name: 'Last 6 days'},
  {id: 6, name: 'Last 7 days'},
];

const cache = {
  country: '_all_',
  untilId: untilDts[0].id,
  paidCust: false,
}

function CustomersPage() {
  const navigation = useNavigate();
  const page = useRef(1);
  const inited = useRef(false);
  const [data, setData] = useState(custData.getPage());
  const [isLoading, setIsLoading] = useState(false);
  const [showEnt, setShowEnt] = useState(null);
  const [filterCountry, setFilterCountry] = useState(cache.country);
  const [untilId, setUntilId] = useState(cache.untilId);
  const [paidCust, setPaidCust] = useState(cache.paidCust);

  useEffect(() => {
    if (!inited.current) {
      loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async (p = 1, {countryCode = '', _untilId, _paidCust} = {}) => {
    page.current = p;
    countryCode = countryCode || filterCountry;
    if (countryCode === '_all_') countryCode = '';

    _untilId = _untilId ?? untilId ?? null;
    const until_dt = _untilId === null || _untilId >= untilDts[0].id ? null : helper.dtToUtcStr(helper.date({utc: false, addedDay: -_untilId, startOfDay: true}));

    cache.country = countryCode;
    cache.untilId = _untilId ?? untilDts[0].id;

    _paidCust = _paidCust ?? paidCust ?? false;
    cache.paidCust = _paidCust;

    setIsLoading(true);
    const res = await custData.loadList(p, {countryCode, until_dt, paidCust: _paidCust});
    setData(res);
    setIsLoading(false);
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const email = e.target[0].value;
    navigation('/customers/email/' + email);
  }

  const onMailChange = (e) => {
    const cust = custData.itemForId(e?.target?.value);
    if (cust?.email) navigation('/customers/email/' + cust.email);
  }

  if (isLoading) return <LoadingComp />;

  return (
    <div>
      <ModalCentered show={showEnt?.id} onHide={() => setShowEnt(null)} headerTitle={'Entitlement'}>
        <EntitlementPage id={showEnt?.id}/>
      </ModalCentered>
      <div disabled={isLoading} className="filterControls">
        <span>
          <Form onSubmit={handleSearch}>
            <div className="form-item">
              <Form.Control size="sm" type="email" placeholder="email" disabled={isLoading} required onChange={onMailChange} />
            </div>
          </Form>
        </span>
        <span>
          <div className="form-item" style={{maxWidth: 180}}>
            <Form.Control
              disabled={isLoading}
              size="sm"
              as="select"
              value={filterCountry}
              onChange={e => {
                if (e.target.value === filterCountry) return;
                setFilterCountry(e.target.value);
                custData.reset();
                loadData(1, {countryCode: e.target.value});
              }}
            >
              <option value="_all_">All countries</option>
              {countries.getCodes().map(code =>
                <option key={code} value={code}>
                  {countries.getName(code)}
                </option>
              )}
            </Form.Control>
          </div>
        </span>
        <span>
          <div className="form-item" style={{maxWidth: 180}}>
            <Form.Control
              disabled={isLoading}
              size="sm"
              as="select"
              value={untilId}
              onChange={e => {
                if (e.target.value === untilId) return;
                setUntilId(e.target.value);
                custData.reset();
                loadData(1, {_untilId: e.target.value});
              }}
            >
              {untilDts.map(obj =>
                <option key={'untildt'+obj.id} value={obj.id}>
                  {obj.name}
                </option>
              )}
            </Form.Control>
          </div>
        </span>
        <span>
          <Form.Check 
            type="switch"
            id="custom-switch"
            label='Paid'
            checked={paidCust}
            onChange={() => {
              const val = !paidCust;
              setPaidCust(val);
              custData.reset();
              loadData(1, {_paidCust: val});
            }}
          />
        </span>
        <span>
          <PrevIconBtn
            disabled={page.current <= 1} 
            onClick={() => loadData(page.current-1)}
            />
          <NextIconBtn
            disabled={page.current > custData.loadedPage() || (page.current === custData.loadedPage() && custData.allPagesLoaded())}
            onClick={() => loadData(page.current+1)}
            />
          <RefreshIconBtn busy={isLoading} onRefresh={() => {
            page.current = 1;
            custData.reset();
            loadData();
          }}/>
        </span>
      </div>
      <Table striped bordered>
          <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Business</th>
                <th>Country</th>
                <th>Revenue</th>
                <th>Entitlement</th>
                <th>Expires</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item, index) =>
              <tr key={item.id}>
                <td>{1 + index + ((page.current-1) * 10)}</td>
                <td>{item.name}</td>
                <td><Link to={`/customers/email/${item.email}`}>{item.email}</Link></td>
                <td>{item.busType}</td>
                <td>{item.countryName}</td>
                <td>{item?.purchTotals?.averagesStr?.join(', ')}</td>
                <td>
                  <Button variant="link" size="sm" onClick={() => setShowEnt(item?.myEnt)}>
                    {item?.myEntName}
                  </Button>
                </td>
                <td>{item.getMyEntExpiresInStr()}</td>
              </tr>
            )}
          </tbody>
        </Table>
    </div>
  );
}

export default CustomersPage;
