import { GeneralData } from "../../general";

export class UsageItemId {
  /**
   * 
   * @param {string} yyyymm 
   * @param {string} dd 
   */
  constructor(yyyymm, dd) {
    this.yyyymm = yyyymm;
    this.dd = dd ?? null;
  }
  isEqualTo(other) { return this.yyyymm === other?.yyyymm && this.dd === other?.dd; }
  toString() { return `${this.yyyymm}${this.dd ?? ''}`; }
  get numval() {
    if (!this._numval) this._numval = parseInt(`${this.yyyymm}${this.dd || '00'}`);
    return this._numval;
  }
  get year() {
    if (!this._year) this._year = parseInt(this.yyyymm.substring(0, 4));
    return this._year;
  }
  get month() {
    if (!this._month) this._month = parseInt(this.yyyymm.substring(4, 6));
    return this._month;
  }
  get monthStr() {
    if (!this._monthStr) this._monthStr = GeneralData.monthNamesShort[this.month];
    return this._monthStr;
  }
  get day() { return this.dd ? parseInt(this.dd) : undefined }
  get utcdate() {
    const date = new Date(Date.UTC(this.year, this.month-1, (this.day || 1), 0, 0, 0, 0));
    return date;
  }
  get numberOfDaysInMonth() {
    const date = this.utcdate;
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    return date.getDate();
  }
  get dateStr() {
    if (!this._dateStr) this._dateStr = `${this.day} ${this.monthStr}, ${this.year}`;
    return this._dateStr;
  }
}

const keyNameMap = new Map([
  ['bus', 'Business'],
  ['all', 'All'],
  ['newb', 'New'],
  ['sub', 'Subscribed'],
  ['prom', 'Promoted'],
  ['bust', 'Business Type'],
  ['country', 'Country'],
  ['emp', 'Employee'],
  ['loc', 'Location'],
  ['pos', 'POS'],
  ['cds', 'CDS'],
  ['kds', 'KDS'],
  ['prn', 'Printer'],
  ['prod', 'Product'],
  ['ver', 'Version'],
  ['BOF', 'Back Office'],
  ['uni', 'Unique'],
]);

export class UsageByKey {
  constructor(json, shouldCalcAll = false) {
    this.json = json || {};
    this.shouldCalcAll = shouldCalcAll;
  }
  get keys() {
    if (!this._keys) {
      if (this.shouldCalcAll && !this.json.all) this.json.all = null;
      this._keys = Object.keys(this.json) ?? [];
      this._keys.sort();
    }
    return this._keys;
  }
  valueForKey(key) {
    if (this.shouldCalcAll && this.json.all === null) {
      this.json.all = 0;
      this.keys.forEach(key => {
        if (key !== 'all') this.json.all += this.json[key] ?? 0;
      });
    }
    return this.json[key] ?? 0;
  }
  childForKey(key, {shouldCalcAll = false} = {}) {
    const json = this.json[key];
    if (!json || typeof json !== 'object') return undefined;
    if (!this._children) this._children = new Map([[key, new UsageByKey(json, shouldCalcAll)]]);
    else this._children.set(key, new UsageByKey(json, shouldCalcAll));
    return this._children.get(key);
  }

  getName(key) { return keyNameMap.get(key) ?? key?.split('_')?.join('.') }
}

export class UsageItem {
  /**
   * 
   * @param {UsageItemId} id 
   * @param {Object} json 
   */
  constructor(id, json) {
    this.id = id;
    this.update(json);
  }

  update(json) {
    this.json = json || {};
  }

  get resouces() {
    if (!this._resouces) this._resouces = new UsageByKey(this.json.res);
    return this._resouces;
  }

  get business() {
    if (!this._business) this._business = new UsageByKey(this.json.bus);
    return this._business;
  }

  get module() {
    if (!this._module) this._module = new UsageByKey(this.json.mod, true);
    return this._module;
  }

  get version() {
    if (!this._version) this._version = new UsageByKey(this.json.ver);
    return this._version;
  }

  get opened() {
    if (!this._opened) this._opened = new UsageByKey(this.json.open);
    return this._opened;
  }

  get businesNumByType_byCountry() {
    if (!this._businesNumByType_byCountry) this._businesNumByType_byCountry = new UsageByKey(this.json.country);
    return this._businesNumByType_byCountry;
  }

  get businesNumByCountry() {
    if (!this._businesNumByCountry) {
      const bustypeByCountry = this.businesNumByType_byCountry;
      const json = {};
      bustypeByCountry.keys.forEach(country => {
        if (!json[country]) json[country] = 0;
        const busTypes = bustypeByCountry.childForKey(country);
        busTypes?.keys?.forEach(bustype => {
          json[country] += busTypes.valueForKey(bustype) ?? 0;
        });
      });
      this._businesNumByCountry = new UsageByKey(json);
    }
    return this._businesNumByCountry;
  }

  get businesNumByType() {
    if (!this._businesNumByType) {
      const bustypeByCountry = this.businesNumByType_byCountry;
      const json = {all: 0};
      bustypeByCountry.keys.forEach(country => {
        const busTypes = bustypeByCountry.childForKey(country);
        busTypes?.keys?.forEach(bustype => {
          if (!json[bustype]) json[bustype] = 0;
          json[bustype] += busTypes.valueForKey(bustype) ?? 0;
          json.all += busTypes.valueForKey(bustype) ?? 0;
        });
      });
      this._businesNumByType = new UsageByKey(json);
    }
    return this._businesNumByType;
  }

  get osByApp() {
    if (!this._osByApp) this._osByApp = new UsageByKey(this.json.os);
    return this._osByApp;
  }

  get osFlat() {
    if (!this._osFlat) {
      const osByApp = this.osByApp;
      const json = {all: 0};
      osByApp.keys.forEach(osName => {
        const os = osByApp.childForKey(osName);
        os?.keys?.forEach(type => {
          const key = `${osName} ${type}`;
          if (!json[key]) json[key] = 0;
          json[key] += os.valueForKey(type) ?? 0;
          json.all += os.valueForKey(type) ?? 0;
        });
      });
      this._osFlat = new UsageByKey(json);
    }
    return this._osFlat;
  }

  get webOrApp() {
    if (!this._webOrApp) {
      const osByApp = this.osByApp;
      const json = {all: 0};
      osByApp.keys.forEach(osName => {
        const os = osByApp.childForKey(osName);
        os?.keys?.forEach(type => {
          const key = type;
          if (!json[key]) json[key] = 0;
          json[key] += os.valueForKey(type) ?? 0;
          json.all += os.valueForKey(type) ?? 0;
        });
      });
      this._webOrApp = new UsageByKey(json);
    }
    return this._webOrApp;
  }
}






