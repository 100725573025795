import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export function LoadingComp({size}) {
  return (
    <div className="spinner-container">
      <Spinner animation="border" size={size} />
    </div>
  );
}

export function ModalCentered({headerTitle, onHide, onSave, ...props}) {
  const hasFooter = onSave;
  return (
    <Modal
      size="md"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex: 9999999}}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {headerTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      {hasFooter &&
        <Modal.Footer>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      }
    </Modal>
  );
}

export function Breadcrmbs({items, currentTitle, ...props}) {
  const navigation = useNavigate();
  return (
    <>
      {items && items.map(item =>
        <span key={item.to+item.title}>
          <Button variant="link" size="sm" onClick={() => navigation(item.to)}>{item.title}</Button>
          /
        </span>
      )}
      {currentTitle && <Button variant="link" size="sm" disabled={true}>{currentTitle}</Button>}
    </>
  );
}
