import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker';
import { LoadingComp } from "../../comps/comps";
import { GeneralData } from "../../data/general";
import custData, { Customer } from "../../data/customers";
import offeringsData from "../../data/offerings";
import entsData from "../../data/entitlements";
import * as helper from './../../core/helper';

function EditEnt({custEmail, onDone}) {
  const [customer] = useState(custData.itemForEmail(custEmail));
  const inited = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entId, setEntId] = useState(customer?.myEnt?.id ?? '_none_');
  const [dtUntil, setDtUntil] = useState(customer?.myEntDtUntil);
  const [dtUntilIsUnlim, setDtUntilIsUnlim] = useState(customer?.myEntUntilDateIsUnlim ?? false);

  useEffect(() => {
    if (!inited.current) loadData();
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    if (entsData.data.length < 1) await entsData.loadList();
    setIsLoading(false);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const ok = await custData.updatePromo(customer.id, {
      dt_until: dtUntil ? helper.dtToUtcStr(dtUntil) : null,
      entid: entId === '_none_' ? null : entId,
    });

    setIsLoading(false);
    if (ok) onDone && onDone();
    else alert('Error!');
  }

  if (isLoading) return <LoadingComp />;
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="fc.entId">
        <Form.Label>Select/override entitlement of "{customer?.name}"</Form.Label>
        <Form.Control
          as="select"
          value={entId}
          onChange={e => {
            setEntId(e.target.value);
          }}
        >
          <option value="_none_">None</option>
          {entsData.data.map(item =>
            <option key={item.id} value={item.id}>
              {item.nameAll}
            </option>
          )}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="fc.dtUntil">
        <Form.Label>Valid until</Form.Label>
        <Form.Check 
          type="checkbox"
          id="hasDiscFor12Months"
          label="Forever"
          checked={dtUntilIsUnlim}
          onChange={e => {
            setDtUntil(!dtUntilIsUnlim ? helper.dtForeverPromo() : null);
            setDtUntilIsUnlim(!dtUntilIsUnlim);
          }}/>
        
        {!dtUntilIsUnlim && <DateTimePicker
          value={dtUntil}
          onChange={newDate => {
            setDtUntil(newDate);
          }}
        />}
      </Form.Group>

      <Button variant="primary" type="submit">Submit</Button>
    </Form>
  );
}

export default EditEnt;