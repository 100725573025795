import { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Dropdown, Row } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import PaymentsChartView, { PaymentsChartDataTransformer } from "./comps/payments-chart-view";
import AvgcheckChartView, { AvgcheckChartDataTransformer } from "./comps/avgcheck-chart-view";
import MrrChartView from "./comps/mrr-chart-view";
import * as helper from './../../core/helper';
import * as model from "../../data/report/payment/model";
import ds from "./../../data/report/payment/payment-events";
import { GeneralData } from "../../data/general";
import { RefreshIconBtn } from "../../comps/icon-btns";

const defFromDate = () => helper.date({utc: false, addedMonth: -1, startOfDay: true});
const defToDate = () => helper.date({utc: false, addedDay: 1, startOfDay: true});

const cache = {
  from: defFromDate(),
  to: defToDate(),
  currType: model.CurrType.USD,
  refreshCount: 0,
  loadKey: '',
  lastLoadedDate: new Date(),
}

function BillingReportsPage() {
  const [from, setFrom] = useState(cache.from);
  const [to, setTo] = useState(cache.to);
  const [currType, setCurrType] = useState(cache.currType);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(cache.refreshCount);
  const anyLoaded = useRef(false);
  const appEnv = useRef(GeneralData.appEnv());
  const srcPayments = useRef(new PaymentsChartDataTransformer());
  const srcAvgcheck = useRef(new AvgcheckChartDataTransformer());

  useEffect(() => {
    cache.from = from;
    cache.to = to;
    cache.currType = currType;
    cache.refreshCount = refresh;
  }, [from, to, currType, refresh]);

  useEffect(() => {
    const key = `${currType.name}; ${from}; ${to} [refresh]${refresh}`;
    let force = key.substring(key.indexOf('[refresh]')) !== cache.loadKey.substring(cache.loadKey.indexOf('[refresh]'));
    if ((new Date()).getTime() - cache.lastLoadedDate.getTime() > 5 * 60 * 1000) force = true;

    if (anyLoaded.current && !force && (key === cache.loadKey || loading)) return;
    cache.lastLoadedDate = new Date();
    cache.loadKey = key;
    anyLoaded.current = true;
    setLoading(true);

    (async () => {
      let dataType = model.DataType.day;
      const daysDiff = helper.dtDaysDiff(from, to);
      if (daysDiff <= 2) dataType = model.DataType.hour;
      else if (daysDiff > 30*6) dataType = model.DataType.month;

      const srcs = [srcPayments.current, srcAvgcheck.current];
      srcs.forEach(src => src.resetResult(false));

      await ds.month.fetchData({from: helper.dtDirectToUtc(helper.dtAddedDay(-367, new Date(from))), to: helper.dtDirectToUtc(to), dataType: model.DataType.month, force});
      await ds.def.fetchData({from: helper.dtDirectToUtc(from), to: helper.dtDirectToUtc(to), dataType, force});
      await ds.def.getTotalItems({
        currType: currType,
        onAdd: (res) => {
          for (let i = 0; i < srcs.length; i++) {
            srcs[i].addResult(res);
          }
        },
      });

      setLoading(false);
      srcs.forEach(src => src.notify());
    })();
  }, [from, to, currType, loading, refresh]);

  return (
    <>
    <Container>
      <Row>
        <Col lg={12}>
          <div disabled={loading} className="filterControls">
            <span>
              <DateTimePicker disabled={loading} value={from} maxDate={to} onChange={newDate => { setFrom(newDate ?? defFromDate()); }} />
            </span>
            <span>
              <DateTimePicker disabled={loading} value={to} minDate={from} onChange={newDate => { setTo(newDate ?? defToDate()); }} />
            </span>
            <span>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="currType" size="sm">{currType?.displayName}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {model.CurrType.ALL.map(item =>
                    <Dropdown.Item key={item.name} onClick={() => setCurrType(item)}>
                      {item.displayName}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
            <span>
              <RefreshIconBtn size="small" busy={loading} onRefresh={() => setRefresh(refresh+1)} />
            </span>
            {appEnv.current.name === model.EnvType.test.name && <><span></span><span><Badge pill>test</Badge></span></>}
          </div>
        </Col>
      </Row>
      <Row><Col><hr/></Col></Row>
      <Row>
        <Col sm={12}>
          <PaymentsChartView loading={loading} src={srcPayments.current} currType={currType} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <AvgcheckChartView loading={loading} src={srcAvgcheck.current} currType={currType} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <MrrChartView loading={loading} currType={currType} from={from} to={to} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
    </Container>
    </>
  );
}

export default BillingReportsPage;



