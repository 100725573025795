import axios from 'axios';
import * as countries from "country-list";
import api from "./../../api/api";
import bofsettings from "./../bof-settings";
import * as helper from './../../core/helper';

class CustomersMatomo {
  constructor() {
    this.data = [new CustomerMatomoVo()];
    this.byId = new Map([['', this.data[0]]]);
    this.activeBuses = null;
    this.countryCodes = new Set(['']);
    this.langCodes = new Set(['']);
    this.matomoBusByEmail = new Map([['', new MatomoBusVo()]]);
    this.totalReset();
  }

  reset() {
    this.data = [];
    this.byId.clear();
    this.matomoBusByEmail.clear();
  }

  totalReset() {
    this.reset();
    this.activeBuses = null;
  }

  async load({dates = []} = {}) {
    await this.loadActiveBuses();
    try {
      const reqs = dates.map(date => new Promise(async (resolve) => resolve(await this.loadBusForDay(date))));
      let data = [new BusMatomoVo()];
      data = data = (await Promise.all(reqs)).flat();

      this.data = [];
      this.byId.clear();
      this.countryCodes.clear();
      this.langCodes.clear();

      data?.forEach(bus => {
        let item = this.byId.get(bus.id);
        if (!item) {
          item = new CustomerMatomoVo({bus, activeInfoJson: this.activeBuses[bus.emailid]});
          this.data.push(item);
          this.byId.set(item.id, item);
        } else {
          item.buses.push(bus);
        }

        if (item.activeInfo.langCode) this.langCodes.add(item.activeInfo.langCode);
        if (item.activeInfo.countryCode) this.countryCodes.add(item.activeInfo.countryCode);
      });
    } catch(e) {
      console.log(e);
    }

    this.data.sort((a, b) => b.buses.length - a.buses.length);

    return this.data;
  }

  async loadBusForDay(date) {
    let data = [new BusMatomoVo()];
    data = [];
    try {
      const props = {
        method: 'CustomDimensions.getCustomDimension',
        idDimension: 1,
        period: 'day',
        date: date,
        filter_limit: -1,
      };
      const path = await bofsettings.matomoUrl(props);
      if (!path) return data;
      
      const resp = await axios.get(path);
      const seen = new Set();
      resp.data?.forEach(element => {
        const item = new BusMatomoVo(element);
        if (seen.has(item.id)) return;
        seen.add(item.id);
        data.push(item);
      });
    } catch(e) {
      console.log(e);
    }
    return data;
  }

  async loadActiveBuses() {
    if (this.activeBuses) return;
    this.activeBuses = {};
    try {
      let path = '/bof/matomo/activebuses';
      const resp = await api.inst.get(path);
      this.activeBuses = resp?.data;
    } catch(e) {
      console.log(e);
    }
  }

  async loadBusWithEmailid(emailid) {
    const vo = new MatomoBusVo();
    try {
      let path = '/bof/matomo/bus/' + emailid;
      const resp = await api.inst.get(path);
      vo.update(resp?.data);
      if (vo?.email) this.matomoBusByEmail.set(vo.email, vo);
    } catch(e) {
      console.log(e);
    }
    return vo;
  }
}

class CustomerMatomoVo {
  constructor({bus = new BusMatomoVo(), activeInfoJson} = {}) {
    this.buses = [bus ?? new BusMatomoVo()];
    this.activeInfo = new BusActiveVo(activeInfoJson);
  }

  get id() { return this.bus.id }
  get bus() { return this.buses[0] }
}

class BusMatomoVo {
  #id = '';
  #email = '';
  #emailid = '';
  #busType = '';

  constructor(json) {
    this.update(json);
  }

  update(json) {
    this.json = json || {};
  }

  get label() { return this.json?.label }
  get visDevice() { return this.json?.nb_uniq_visitors }
  get segment() { return this.json?.segment }

  get id() {
    if (!this.#id) {
      const arr = decodeURIComponent(this.json?.segment ?? this.label ?? '').split('dimension1==');
      this.#id = arr[arr.length - 1];
    }
    return this.#id;
  }

  get email() {
    if (!this.#email) {
      this.#email = helper.decrypt(bofsettings?.data?.matomoIdEncKey, (this.id ?? '').split('~')[0] + '~');
    }
    return this.#email;
  }

  get emailid() {
    if (!this.email) return null;
    if (!this.#emailid) {
      this.#emailid = helper.emailidOf(this.email);
    }
    return this.#emailid;
  }

  get busType() {
    if (!this.#busType) {
      const arr = (this.id ?? '').split('~');
      this.#busType = arr[arr.length - 1];
      if (this.#busType.startsWith('-')) this.#busType = this.#busType.substring(1);
    }
    return this.#busType;
  }
}

class AbstractLastAction {
  #lastActionDate;
  #lastActionHuman = '';
  #lastActionDaysAgo = 0;

  get lastActionSec() { return this.json.lasec }

  /**
   * @return {Date}
   */
   get lastActionDate() {
    if (!this.lastActionSec) return null;
    if (!this.#lastActionDate) this.#lastActionDate = new Date(this.lastActionSec * 1000);
    return this.#lastActionDate;
  }

  get lastActionDaysAgo() {
    if (!this.lastActionDate) return null;
    if (!this.#lastActionDaysAgo) this.#lastActionDaysAgo = helper.dtDaysDiff(this.lastActionDate, new Date());
    return this.#lastActionDaysAgo;
  }

  get lastActionHuman() {
    if (!this.lastActionDaysAgo) return null;
    if (!this.#lastActionHuman) this.#lastActionHuman = this.lastActionDaysAgo.toFixed(2) + 'd. ago';
    return this.#lastActionHuman;
  }
}

class BusActiveVo extends AbstractLastAction {
  constructor(json) {
    super();
    if (json) this.update(json);
  }

  update(json) {
    this.json = json ?? this.json ?? {};
  }

  get lastActionSec() { return this.json?.s }
  get langCode() { return this.json?.l }
  get countryCode() { return this.json?.c }

  get language() { return helper.Languages.nameWithCode(this.langCode) }
  get country() { return this.countryCode ? countries.getName(this.countryCode) : '' }

  
}

export class MatomoBusVo extends BusActiveVo {
  constructor() {
    super();
    this.json = {};
    this._devices = [];
    this._deviceById = new Map();
    this.resources = new MatomoBusResource();
  }

  update(json) {
    super.update(json);
    this._devices = [];
    this._deviceById.clear();
    this.resources.update(this.json.resources);

    let daysSinceFirstVisit = -1;
    const keys = Object.keys(this.json);
    keys.forEach(key => {
      if (key.startsWith('d_')) {
        const device = new MatomoBusDevice(this.json[key]);
        this.devices.push(device);
        this.deviceById.set(device.id, device);

        if (device.daysSinceFirstVisit)
        if (daysSinceFirstVisit < 0 || daysSinceFirstVisit < device.daysSinceFirstVisit) {
          daysSinceFirstVisit = device.daysSinceFirstVisit;
        }
      }
    });

    if (daysSinceFirstVisit > -1 && (!this.json.fvisd || daysSinceFirstVisit > this.json.fvisd)) {
      this.json.fvisd = Math.round(daysSinceFirstVisit);
    }

    this.devices.sort((a, b) => b.lastActionSec - a.lastActionSec);
  }

  get lastActionSec() { return this.json.lasec }
  get langCode() { return this.json.lang }
  get countryCode() { return this.json.ctry }

  get email() { return this.json.email }
  get busType() { return this.json.type }
  get daysSinceFirstVisit() { return this.json.fvisd }

  /**
   * @return {[MatomoBusDevice]}
   */
  get devices() { return this._devices }

  /**
   * @return {Map<string, MatomoBusDevice>}
   */
   get deviceById() { return this._deviceById }
}

export class MatomoBusResource {
  constructor() {
    this.update();
  }

  update(json) {
    this.json = json || {};
  }

  get hasData() { return this.json?.loc !== undefined }

  get location() { return this.#getAsInt('loc') }
  get pos() { return this.#getAsInt('pos') }
  get product() { return this.#getAsInt('pro') }
  get ingredient() { return this.#getAsInt('ing') }
  get employee() { return this.#getAsInt('emp') }

  #getAsInt(key) {
    const cached = '_cache'+key;
    if (this[cached] === undefined) {
      this[cached] = parseInt(`${this.json[key] ?? 0}`);
    }
    return this[cached];
  }
}

class MatomoBusDevice extends AbstractLastAction {
  constructor(json) {
    super();
    this.json = json ?? {};
  }

  get id() { return this.json.devid }
  get daysSinceFirstVisit() { return Math.ceil(Math.max((this.lastActionDaysAgo ?? 0), (this.json.fvisd ?? 0))) }
  get visitCount() {
    if (Array.isArray(this.json.viscnt)) this.json.viscnt = this.json.viscnt[this.json.viscnt.length-1];
    return this.json.viscnt;
  }

  get browsers() { return ListOfVals.from(this.json, 'brw') }
  get deviceTypes() { return ListOfVals.from(this.json, 'devt') }
  get oss() { return ListOfVals.from(this.json, 'os') }
  get osVersions() { return ListOfVals.from(this.json, 'osv') }
  get resolutions() { return ListOfVals.from(this.json, 'res') }

  get countryCodes() { return ListOfVals.from(this.json, 'ctry') }
  get country() { return this.countryCodes.first ? countries.getName(this.countryCodes.first) : '' }

  get languageCodes() { return ListOfVals.from(this.json, 'lang') }
  get language() { return helper.Languages.nameWithCode(this.languageCodes.first) }

  get isWeb() { return this.json.web }
  get isWebDeskApp() { return this.isWeb && this.browsers.first === 'Chrome 96.0' && this.oss.first === 'Windows' }

  get osDesc() {
    if (!this.oss.first) return '';
    let str = this.oss.first;
    if (this.isWebDeskApp) str += '/Webapp';
    else if (this.isWeb) str += '/Browser'
    return str;
  }
}

class ListOfVals {
  constructor(arr) {
    this.arr = arr ?? [];
    this.first = this.arr.length > 0 ? this.arr[0] : null;
    this.joined = this.arr.join(', ');
  }

  /**
   * 
   * @param {*} json 
   * @param {*} key 
   * @returns {ListOfVals}
   */
  static from(json, key) {
    const cached = '_cache'+key;
    if (json[cached] === undefined) {
      json[cached] = new ListOfVals(json[key]);
    }
    return json[cached];
  }
}

const data = new CustomersMatomo();
export default data;


