import { useEffect, useRef, useState } from "react";
import { Badge, Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrmbs, ModalCentered } from "../comps/comps";
import { RefreshIconBtn } from "../comps/icon-btns";
import dataSource from "./../data/offerings";
import EntitlementPage from "./entitlement-page";

function OfferingsPage() {
  const navigation = useNavigate();
  const inited = useRef(false);
  const [data, setData] = useState(dataSource.data);
  const [preferedWebOfferings, setPreferedWebOfferings] = useState(dataSource.preferedWebOfferings);
  const [isLoading, setIsLoading] = useState(false);
  const [showEnt, setShowEnt] = useState(null);

  useEffect(() => {
    if (!inited.current) {
      loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async (p = 1) => {
    setIsLoading(true);
    const res = await dataSource.loadList();
    setData(res);
    setPreferedWebOfferings(dataSource.preferedWebOfferings);
    setIsLoading(false);
  }

  return (
    <div>
      <ModalCentered show={showEnt?.id} onHide={() => setShowEnt(null)} headerTitle={'Entitlement'}>
        <EntitlementPage id={showEnt?.id}/>
      </ModalCentered>
      <div className="filterControls">
        <span>
          <Breadcrmbs currentTitle="Offerings" />
        </span>
        <span>
          <RefreshIconBtn busy={isLoading} onRefresh={() => {
            dataSource.reset();
            loadData();
          }}/>
        </span>
      </div>
      <div>
        <Container>
          <Row>
            <Col xl={8}>
              <Table striped bordered>
                <thead>
                  <tr>
                      <th>Id</th>
                      <th>Type</th>
                      <th>Active</th>
                      <th>Fallback Entitlement</th>
                      <th>Fallback Entitlement (kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map(item =>
                    <tr key={item.id}>
                      <td><Link to={`/offerings/${item.id}`}>{item.id}</Link></td>
                      <td>{item.type}</td>
                      <td><Badge bg={item?.active ? 'success' : 'warning'}>{item?.activeStr}</Badge></td>
                      <td>{!item?.fallbackEnt ? '-' :
                        <Button variant="link" size="sm" onClick={() => setShowEnt(item?.fallbackEnt)}>
                          {item?.fallbackEnt?.name()}
                        </Button>}
                      </td>
                      <td>{!item?.fallbackEntKg ? '-' :
                        <Button variant="link" size="sm" onClick={() => setShowEnt(item?.fallbackEntKg)}>
                          {item?.fallbackEntKg?.name()}
                        </Button>}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered>
                <thead>
                  <tr>
                      <th>Country</th>
                      <th>Prefered offerings (non-inapp)</th>
                  </tr>
                </thead>
                <tbody>
                  {preferedWebOfferings && preferedWebOfferings.map(item =>
                    <tr key={item.country}>
                      <td>{item.country}</td>
                      <td><Link to={`/offerings/${item.offeringsId}`}>{item.offeringsId}</Link></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OfferingsPage;
