import api from "./../api/api";

class BofSettings {
  constructor() {
    this.data = new BofSettingsVo();
  }

  reset() {
    this.data = new BofSettingsVo();
  }

  async load() {
    if (this.data.hasData) return this.data;
    try {
      let path = '/bof/settings';
      const resp = await api.inst.get(path);
      this.data.update(resp?.data);
    } catch(e) {}
    return this.data;
  }

  async matomoUrl(props) {
    const vo = await this.load();
    if (!vo.hasData) return null;
    let path = 'https://analytics.altospos.com';
    path += '/matomo/?module=API&idSite=1&format=json';
    path += '&token_auth=' + vo.matomoApiKey;

    const keys = Object.keys(props);
    keys?.forEach(key => {
      let val = props[key];
      if (val instanceof Date) {
        let str = val.getFullYear() + '-';
        const month = val.getMonth() + 1;
        str += (month < 10 ? '0' : '') + month + '-';
        const day = val.getDate();
        str += (day < 10 ? '0' : '') + day;
        val = str;
      }
      path += `&${key}=${val}`;
    });
    return path;
  }
}

class BofSettingsVo {
  constructor() {
    this.update({});
  }

  update(json) {
    this.json = json || {};
  }

  get hasData() { return this.matomoApiKey }
  get matomoApiKey() { return this.json?.matomoApiKey }
  get matomoIdEncKey() { return this.json?.matomoIdEncKey }
}

const data = new BofSettings();
export default data;


