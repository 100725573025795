import { useEffect, useRef, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { GeneralData } from "../../../data/general";
import * as helper from './../../../core/helper';
import * as model from "../../../data/report/payment/model";
import ds from "./../../../data/report/payment/payment-events";

/**
 * 
 * @param {{currType: model.CurrType, from: Date, to: Date}} param0 
 * @returns 
 */
export async function getMrrDataForChart({currType, from, to} = {}) {
  const toId = (date) => helper.dateIdWithDate({date, hasYear: true, hasMonth: true, hasDay: false});
  const toIdNum = (date) => parseInt(toId(date));
  const fromYM = toIdNum(from);
  const toYM = toIdNum(to);

  const results = await ds.month.getTotalItems({currType});
  const arr = [];
  const objs = [];
  const getObj = (i, res) => {
    if (!objs[i]) {
      objs[i] = {
        name: '',
        nameLong: '',
        value: 0,
      };
    }
    if (res) {
      objs[i].name = res.dtName;
      objs[i].nameLong = res.dtNameLong;
    }
    return objs[i];
  }

  let refundTotal = 0;
  for (let i = 0; i < results.length; i++) {
    const res = results[i];
    const idYM = toIdNum(res.date);

    const m1 = (res.sumItem(model.PurchType.all, model.MonthType.month1)?.positive ?? 0) / 1;
    const m3 = (res.sumItem(model.PurchType.all, model.MonthType.month3)?.positive ?? 0) / 3;
    const m6 = (res.sumItem(model.PurchType.all, model.MonthType.month6)?.positive ?? 0) / 6;
    const m12 = (res.sumItem(model.PurchType.all, model.MonthType.month12)?.positive ?? 0) / 12;
    const m1Rec = (res.sumItem(model.PurchType.all, model.MonthType.month1Recurring)?.positive ?? 0) / 1;
    const m12Rec = (res.sumItem(model.PurchType.all, model.MonthType.month12Recurring)?.positive ?? 0) / 12;
    // const recSingleCanceled = (res.sumItem(model.PurchType.cancel, model.MonthType.singleRecurring)?.positive ?? 0) / 1;
    refundTotal += (res.sumItem(model.PurchType.all, model.MonthType.all)?.negative ?? 0)

    const obj = getObj(i, res);
    if (idYM >= fromYM-1 && idYM <= toYM) {
      arr.push(obj);
      obj.value += m1 + m3 + m6 + m12 + m1Rec + m12Rec;
    }

    let total = m1 + m3 + m6 + m12 + m12Rec;
    getObj(i+1).value   += total - m1;
    getObj(i+2).value   += total - m1;
    getObj(i+3).value   += total - m1 - m3;
    getObj(i+4).value   += total - m1 - m3;
    getObj(i+5).value   += total - m1 - m3;
    getObj(i+6).value   += total - m1 - m3 - m6;
    getObj(i+7).value   += total - m1 - m3 - m6;
    getObj(i+8).value   += total - m1 - m3 - m6;
    getObj(i+9).value   += total - m1 - m3 - m6;
    getObj(i+10).value  += total - m1 - m3 - m6;
    getObj(i+11).value  += total - m1 - m3 - m6;
  }

  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      const refund = Math.max(-arr[i].value, refundTotal/(arr.length - i));
      refundTotal -= refund;
      arr[i].value += refund;
      arr[i].value = parseFloat(arr[i].value.toFixed(2));
    }
  }

  return arr;
}

/**
 * 
 * @param {{loading: Boolean, currType: model.CurrType, from: Date, to: Date}} param0 
 * @returns 
 */
function MrrChartView({loading, currType, from, to}) {
  const [data, setData] = useState([]);
  const timer = useRef(1);
  
  useEffect(() => {
    clearTimeout(timer.current);
    if (loading) return;
    timer.current = setTimeout(async () => {
      const arr = await getMrrDataForChart({currType, from, to});
      setData(arr);
    }, 10);
  }, [loading, currType, from, to]);

  const curr = currType?.displayName;
  const colors = GeneralData.chartColors;

  return (
    <Card>
      <Card.Body>
        <Card.Title>Monthly Recurring Revenue {curr}</Card.Title>
      </Card.Body>
      <div style={styles.chart}>
        <ResponsiveContainer width="100%" aspect={2} maxHeight={240}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="1 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              formatter={(value, name, props) => [`${helper.finFormatAmount(value, {currency: curr})} MRR`]}
              labelFormatter={(label, props) => <small>{props[0]?.payload?.nameLong}</small>}
              />
            <Line dataKey='value' name="MRR" type="monotone" stroke={colors[0]} animationDuration={800} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

export default MrrChartView;

const styles = {
  chart: {
    padding: 8,
  },
}



