import API from "./../api/api";

class Revcat {
  constructor() {
    const arr = API.baseURL.split('/');
    if (arr[arr.length-1] === 'api') arr.pop();
    this.api = API.instWithBaseURL(arr.join('/'));
  }

  async execHook(hook) {
    let success = false;
    try {
      let path = '/inapp/webhooks/incoming';
      const resp = await this.api.post(path, {__data: hook});
      success = resp.status === 200;
    } catch(e) {
      success = false;
      console.log(e);
    }

    return success;
  }
}

const data = new Revcat();
export default data;


