import api from "../../../api/api";
import * as helper from '../../../core/helper';
import { AbstractItem, Daily, Hourly, Monthly } from "./model";

class PaymentEventsAbstract {
  #url = '';
  #loading = false;

  constructor(type, url, cacheid) {
    this._type = type;
    this.#url = url;
    this._data = [new AbstractItem()];
    this._data.pop();
    this._byId = new Map([['', new AbstractItem()]]);
    this._byId.clear();
    this._cache = new helper.Cache(cacheid || type);
  }

  get cacheId() { return this._cache.id }

  async load() {
    if (this.#loading) return this._data;
    this.#loading = true;

    const itemsFromCache = [];
    let nextDate = this._startDate();
    let toDate = nextDate;
    while (nextDate) {
      const id = this._createId(nextDate);
      const json = this._cache.read(id);
      if (json) {
        const item = this._createItem(json);
        itemsFromCache.push(item);
      }
      toDate = nextDate;
      nextDate = this._nextPossibleDateId(nextDate);
    }
    toDate = this._nextPossibleDateId(toDate, false);

    const fromDate = itemsFromCache.length < 1 ? this._startDate() : itemsFromCache[itemsFromCache.length-1].crd;
    const fromUtcStr = !fromDate ? null : helper.dtToUtcStr(fromDate);
    const toUtcStr = !toDate ? null : helper.dtToUtcStr(toDate);

    try {
      const data = await this.#load({fromUtcStr, toUtcStr});
      if (data) {
        const loadedids = new Set(data.map(item => item.id));
        itemsFromCache?.forEach(item => {
          if (!loadedids.has(item.id)) {
            data.push(item);
          }
        });
      }

      this._data = data ?? [];
      this._data.sort((a, b) => a.sortIndex - b.sortIndex);
    } catch(e) {console.error(e)}

    this.#loading = false;
    return this._data;
  }

  async #load({fromUtcStr, toUtcStr}) {
    const params = [];
    if (fromUtcStr) params.push('from=' + fromUtcStr);
    if (toUtcStr) params.push('to=' + toUtcStr);

    const path = this.#url + (params.length < 1 ? '' : ('?' + params.join('&')));

    const resp = await api.inst.get(path);
    const data = [new AbstractItem()];
    data.pop();
    const loadedData = resp?.data?.items ?? resp?.data;
    loadedData?.forEach(json => {
      const item = this._createItem(json);
      this._byId.set(item.id, item);
      this._cache.write(item.id, json);
      data.push(item);
    });
    return data;
  }

  _createItem(json = {}) { return new AbstractItem(json) }
  _createId(date = new Date()) { return '' }
  _startDate() { return new Date() }
  _nextPossibleDateId(date, shouldLimit = true) { return new Date() }
}

export class PaymentEventsHourly extends PaymentEventsAbstract {
  constructor(daily = new Daily()) {
    super('hourly', `bof/reports/payment/events/months/${daily?.parent?.id}/days/${daily?.id}/hours`, `m_${daily?.parent?.id}_d_${daily?.id}_h`);
    this.daily = daily;
    this._data = [new Hourly()];
    this._data.pop();
    this._byId = new Map([['', new Hourly()]]);
    this._byId.clear();
  }

  _createItem(json = {}) { return new Hourly(json, this.daily) }
  _createId(date = new Date()) { return helper.dateIdWithDate({date, hasYear: false, hasMonth: false, hasDay: false, hasHour: true}) }
  _startDate() {
    const date = new Date(this.daily.crd);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    return date;
  }
  _nextPossibleDateId(date, shouldLimit = true) {
    const nextDate = new Date(date);
    nextDate.setHours(nextDate.getHours() + 1);
    if (!shouldLimit) return nextDate;
    return date?.getUTCDate() !== nextDate?.getUTCDate() ? null : nextDate;
  }

  get data() { return this._data }
  itemWithId(id) { return this._byId?.get(id) }
  async load() {
    await super.load();
    return this.data;
  }
}

export class PaymentEventsDaily extends PaymentEventsAbstract {
  constructor(monthly = new Monthly()) {
    super('daily', `bof/reports/payment/events/months/${monthly?.id}/days`, `m_${monthly?.id}_d`);
    this.monthly = monthly;
    this._data = [new Daily()];
    this._data.pop();
    this._byId = new Map([['', new Daily()]]);
    this._byId.clear();
  }

  _createItem(json = {}) { return new Daily(json, this.monthly) }
  _createId(date = new Date()) { return helper.dateIdWithDate({date, hasYear: false, hasMonth: false, hasDay: true}) }
  _startDate() {
    const date = new Date(this.monthly.crd);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    return date;
  }
  _nextPossibleDateId(date, shouldLimit = true) {
    const nextDate = helper.dtAddedDay(1, new Date(date));
    if (!shouldLimit) return nextDate;
    return date?.getUTCMonth() !== nextDate?.getUTCMonth() ? null : nextDate;
  }

  get data() { return this._data }
  itemWithId(id) { return this._byId?.get(id) }
  async load() {
    await super.load();
    return this.data;
  }
}

export class PaymentEventsMonthly extends PaymentEventsAbstract {
  constructor(utcYear = 0) {
    super('monthly', `bof/reports/payment/events/months`, `m`);
    this.utcYear = utcYear && utcYear >= 2021 ? utcYear : 2021;
    this._data = [new Monthly()];
    this._data.pop();
    this._byId = new Map([['', new Monthly()]]);
    this._byId.clear();
  }

  _createItem(json = {}) { return new Monthly(json) }
  _createId(date = new Date()) { return helper.dateIdWithDate({date, hasYear: true, hasMonth: true, hasDay: false}) }
  _startDate() { return new Date(Date.UTC(this.utcYear, 0, 1, 0, 0, 0, 0)); }
  _nextPossibleDateId(date, shouldLimit = true) {
    const nextDate = new Date(date);
    nextDate.setMonth(nextDate.getMonth() + 1);
    if (!shouldLimit) return nextDate;
    return date?.getUTCFullYear() !== nextDate?.getUTCFullYear() ? null : nextDate;
  }

  get data() { return this._data }
  itemWithId(id) { return this._byId?.get(id) }
  async load() {
    await super.load();
    return this.data;
  }
}



