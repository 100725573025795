import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { LoadingComp } from "../../comps/comps";
import custData from "../../data/customers";
import entsData from "../../data/entitlements";

function EditPreferedEntitlements({custEmail, onDone}) {
  const [customer] = useState(custData.itemForEmail(custEmail));
  const inited = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preferedEnts, setPreferedEnts] = useState(customer?.preferedEnts?.clone());
  const [activeEntId, setActiveEntId] = useState('');

  useEffect(() => {
    if (!inited.current) loadData();
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    // if (offeringsData.data.length < 1) await offeringsData.loadList();
    if (entsData.data.length < 1) await entsData.loadList();
    setIsLoading(false);

    if (entsData?.data?.length > 0 && !activeEntId) setActiveEntId(entsData.data[0].id);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const json = {...(preferedEnts?.json ?? {})};
    const entids = Object.keys(json);
    entids?.forEach(entid => {
      const keys = Object.keys(json[entid]);
      keys?.forEach(key => {
        if (key.startsWith('desc')) delete json[entid][key];
      });
    });

    setIsLoading(true);
    const ok = await custData.updatePreferedEnts(customer.id, json);
    setIsLoading(false);
    if (ok) onDone && onDone();
    else alert('Error!');
  }

  if (isLoading) return <LoadingComp />;
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="fc.preferedEnts">
        <Form.Label>Override entitlements for "{customer?.name}"</Form.Label>
        <Form.Control
          as="select"
          value={activeEntId}
          onChange={e => {
            setActiveEntId(e.target.value);
          }}
        >
          {entsData?.data?.map(item =>
            <option key={item.id} value={item.id}>
              {item.name()} {preferedEnts?.exists(item.id) ? '✅' : ''}
            </option>
          )}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="fc.preferedEnts.override">
        <Form.Check 
          type="checkbox"
          id="override"
          label="Override"
          checked={preferedEnts?.exists(activeEntId) ?? false}
          onChange={e => {
            if (preferedEnts?.exists(activeEntId)) setPreferedEnts(preferedEnts.remove(activeEntId).clone());
            else {
              const json = {...(entsData.itemForId(activeEntId).json)};
              if (json?.maxes) json.maxes = reduceMaxesToDef(json.maxes);
              setPreferedEnts(preferedEnts.add(json).clone());
            }
          }}/>
      </Form.Group>
      <br />

      {preferedEnts?.exists(activeEntId) &&
        <EntView obj={{...preferedEnts.getEntWithId(activeEntId).json}} onChange={(obj) => {
          setPreferedEnts(preferedEnts.add(obj).clone());
        }} />
      }

      <Button variant="primary" type="submit">Submit</Button>
    </Form>
  );
}

export default EditPreferedEntitlements;


function EntView({obj, onChange}) {
  const [data, setData] = useState({});
  const [maxes, setMaxes] = useState({});

  useEffect(() => {
    setData(obj);
    setMaxes(obj?.maxes ?? {});
  }, [obj]);

  const upd = (fn) => {
    fn && fn();

    const m = {...maxes};
    const maxkeys = Object.keys(m);
    maxkeys?.forEach(key => {
      if (!m[key]) delete m[key];
    });

    const d = {...data, maxes: m};
    setData(d);
    setMaxes(m);
    onChange && onChange(d);
  }

  if (!data.id) return <></>;

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="fc.preferedEnt.name">
        <Form.Label column sm="3">Name</Form.Label>
        <Col>
          <Form.Control value={data.name} onChange={e => upd(() => data.name = e.target.value)}>
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.preferedEnt.nameRu">
        <Form.Label column sm="3">Name (ru)</Form.Label>
        <Col>
          <Form.Control value={data.name_ru} onChange={e => upd(() => data.name_ru = e.target.value)}/>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="fc.preferedEnt.loc">
        <Form.Label column sm="3">Location</Form.Label>
        <Col>
          <Form.Control value={maxes.loc ?? ''} onChange={e => upd(() => maxes.loc = (parseInt(e.target.value) ?? 0))}/>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="fc.preferedEnt.pos">
        <Form.Label column sm="3">POS</Form.Label>
        <Col>
          <Form.Control value={maxes.pos ?? ''} onChange={e => upd(() => maxes.pos = (parseInt(e.target.value) ?? 0))}/>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="fc.preferedEnt.prod">
        <Form.Label column sm="3">Product</Form.Label>
        <Col>
          <Form.Control value={maxes.prod ?? ''} onChange={e => upd(() => maxes.prod = (parseInt(e.target.value) ?? 0))}/>
        </Col>
      </Form.Group>
    </>
  );
}

function reduceMaxesToDef(maxes) {
  if (!maxes) return maxes;
  maxes = {...maxes};
  const keys = Object.keys(maxes);
  keys?.forEach(key => {
    if (typeof maxes[key] === 'object') {
      maxes[key] = maxes[key].def ?? 0;
    }
  });
  return maxes;
}


