import * as CountryList from "country-list";
import * as helper from './../core/helper';
import { Languages } from "./../core/helper";

export class CustDeviceInfo {
  constructor(json) {
    this.json = json || {};
  }

  get dailyUniqueVisits() {
    if (!this._uniqueVisits) this._uniqueVisits = new CDDUniqueDailyVisits(this.json.uvis, this.json.resources?.dt);
    return this._uniqueVisits;
  }

  get devices() {
    if (!this._devices) {
      const devices = this.json.devices ?? {};
      const ids = Object.keys(devices);
      this._devices = ids.map(id => new CDDevice(devices[id]));
    }
    return this._devices;
  }

  deviceWithId(id) {
    return this.devices.find(device => device.id === id);
  }

  get resources() {
    if (!this._resources) this._resources = new CDDResources(this.json.resources);
    return this._resources;
  }
}

export class CDDUniqueDailyVisits {
  constructor(json, lastUpdDt) {
    this.json = json || {};
    this.#lastUpdDt = lastUpdDt;
  }

  #lastUpdDt;
  #todayDeviceVisits;

  get allBusinessVisits() { return this.json.bus ?? 0 }
  get allDeviceVisits() { return this.json.dea ?? 0 }
  get todayDeviceVisits() {
    if (this.#todayDeviceVisits === undefined) {
      if (this.#lastUpdDt && helper.dtFromUtcStr(this.#lastUpdDt) >= helper.date({utc: true, startOfDay: true})) {
        this.#todayDeviceVisits = this.json.det ?? 0;
      } else {
        this.#todayDeviceVisits = 0;
      }
    }
    return this.#todayDeviceVisits;
  }
}

export class CDDevice {
  constructor(json) {
    this.json = json || {};
  }

  get id() { return this.json.id }
  get city() { return this.json.city }
  get country() { return this.json.country }
  get countryName() { return CountryList.getName(this.country) }
  get lang() { return this.json.lang }
  get langName() { return Languages.nameWithCode(this.lang) }

  get name() { return this.json.name }
  get size() { return this.json.size }
  get version() { return this.json.ver }
  get isWeb() { return this.json.isweb }
  get os() { return this.json.os }
  get appType() { return `${this.os} ${this.isWeb ? 'web' : 'app'}` }

  get module() { return this.json.mod }
  get moduleName() { return this.module === 'BOF' ? 'Back Office' : this.module }
  get moduleId() { return this.json.modid }

  get timezone() { return this.json.timezone }
  get lastUpdateUtcStr() { return this.json.dt }
  get lastUpdateDate() {
    if (!this._lastUpdate) this._lastUpdate = helper.dtFromUtcStr(this.lastUpdateUtcStr);
    return this._lastUpdate;
  }
  get lastUpdateDateStr() {
    if (!this._lastUpdateDateStr) this._lastUpdateDateStr = helper.dtToHumanStr(this.lastUpdateDate, ' ');
    return this._lastUpdateDateStr;
  }
  get lastUpdateDaysAgo() {
    return helper.dtDaysDiff(this.lastUpdateDate, new Date())
  }
}

export class CDDResources {
  constructor(json) {
    this.json = json || {};
  }

  get location() { return this.json.loc ?? 0 }
  get pos() { return this.json.pos ?? 0 }
  get product() { return this.json.prod ?? 0 }
  get ingredient() { return this.json.ingr ?? 0 }
  get employee() { return this.json.emp ?? 0 }
  get cds() { return this.json.cds ?? 0 }
  get kds() { return this.json.kds ?? 0 }
  get printer() { return this.json.prn ?? 0 }

  get lastUpdateUtcStr() { return this.json.dt }
  get lastUpdateDate() {
    if (!this._lastUpdate) this._lastUpdate = helper.dtFromUtcStr(this.lastUpdateUtcStr);
    return this._lastUpdate;
  }
  get lastUpdateDateStr() {
    if (!this._lastUpdateDateStr) this._lastUpdateDateStr = helper.dtToHumanStr(this.lastUpdateDate, ' ');
    return this._lastUpdateDateStr;
  }
}






