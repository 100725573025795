import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import * as CountryList from "country-list";
import * as modelu from "../../../data/report/usage/model";
import { Usages, UsageToChart1 } from "../../../data/report/usage/usages";
import { GeneralData } from "../../../data/general";
import { ChartGeneralTooltip } from "./usage-common-chart";

const colors = GeneralData.chartColors;

/**
 * @callback sortFn
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
/**
 * @callback usageByKeyOfItem
 * @param {modelu.UsageItem} item
 * @returns {modelu.UsageByKey}
 */
/**
 * 
 * @param {{loading: Boolean, ds: Usages, getChild: usageByKeyOfItem, title: string, keysSortFn: sortFn}} param0 
 * @returns 
 */
export function UsageKeySelChart({loading, ds, getChild, title, keysSortFn}) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [key, setKey] = useState();
  
  useEffect(() => {
    const updateData = (items) => {
      if (!items || !ds?.hasListener(updateData)) return;
      const trans = new UsageToChart1(items);
      trans.transform(getChild, {sortFn: keysSortFn});
      setKeys(trans.names);
      setData(trans.objs);
      setKey(trans.names.length > 0 ? trans.names[0] : null);
    }
    ds?.addListener(updateData);
    updateData(ds?.getItems());
    return () => ds?.removeListener(updateData);
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <span disabled={loading} className="filterControls">
            <span>{title}</span>
            <span>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="keys" size="sm">{key ?? 'Select'}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {keys.map(k => 
                    <Dropdown.Item key={k+'c'} onClick={() => setKey(k)}>{k}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </span>
        </Card.Title>
      </Card.Body>
      <div style={styles.chart}>
        <ResponsiveContainer width="100%" aspect={2} maxHeight={240}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="1 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              content={<ChartGeneralTooltip />}
              // formatter={(value, name, props) => [`${value} - ${name}`]}
              // labelFormatter={(label, props) => <small>{props[0]?.payload?.nameLong}</small>}
              />
            <Legend />
            <Line dataKey={key} name={key} type="monotone" stroke={colors[1]} animationDuration={800} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

const styles = {
  chart: {
    padding: 8,
  },
}















