import { useEffect, useRef, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import * as modelu from "../../../data/report/usage/model";
import { Usages, UsageToChart1 } from "../../../data/report/usage/usages";
import { GeneralData } from "../../../data/general";

const colors = GeneralData.chartColors;

/**
 * @callback usageByKeyOfItem
 * @param {modelu.UsageItem} item
 * @returns {modelu.UsageByKey}
 */
/**
 * 
 * @param {{loading: Boolean, ds: Usages, getChild: usageByKeyOfItem, title: string}} param0 
 * @returns 
 */
export function UsageCommonChart({loading, ds, getChild, title, excludeKeys}) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  
  useEffect(() => {
    const updateData = (items) => {
      if (!items || !ds?.hasListener(updateData)) return;
      const trans = new UsageToChart1(items, {excludeKeys});
      trans.transform(getChild);
      setData(trans.objs);
      setKeys(trans.names);
    }
    ds?.addListener(updateData);
    updateData(ds?.getItems());
    return () => ds?.removeListener(updateData);
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
      <div style={styles.chart}>
        <ResponsiveContainer width="100%" aspect={2} maxHeight={280}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="1 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              content={<ChartGeneralTooltip />}
              // formatter={(value, name, props) => [`${value} - ${name}`]}
              // labelFormatter={(label, props) => <small>{props[0]?.payload?.nameLong}</small>}
              />
            <Legend />
            {keys?.map((key, index) =>
              <Line key={key} dataKey={key} name={key} type="monotone" stroke={colors[index]} animationDuration={800} />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

export function ChartGeneralTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div style={{
        // backgroundColor: 'rgb(0, 0, 0, 0.1)',
        maxWidth: 428,
        padding: 8,
        paddingBottom: 0,
        }}>
        <h6 style={{color: "black", paddingLeft: 4}}><small>{payload[0]?.payload?.nameLong}</small></h6>
        <div style={{}}>
          {payload.map((payload, index) => 
            <div style={{padding: 2, paddingBottom: 6, float: 'left'}}>
              <span style={{
                fontSize: 12,
                color: 'white',
                backgroundColor: payload.color,
                padding: 4,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 16,
                }}>
                <b>{`${payload.name} ⇢ `}</b>
                <b>{`${payload.value}`}</b>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const styles = {
  chart: {
    padding: 8,
  },
}















