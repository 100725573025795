import Dinero from 'dinero.js'
import Salsa20 from "./jssalsa20";

export * from './helper-dt';
export * from './cache';
export * from './lang';

export function finFormatAmount(amount, {currency} = {}) {
  if (typeof amount !== 'number') amount = parseFloat(amount);
  const din = Dinero({currency, amount: Math.round((amount ?? 0) * 100)});
  if (!currency) return din.toFormat('0,0.00');
  return din.toFormat();
}

export function emailidOf(email) {
  return '_' + email?.split('.')?.join('_d_').split('@').join('_a_');
}

//
export function encrypt(key, data) {
  if (key == null || (data?.length ?? 0) < 1 || data[data.length - 1] === '~') return data;
  // return getEcrypter(key).encrypt(data, iv: IV.fromLength(8)).base64 + '~';
  key = strToUintArr(getEncKey(key));
  const nonce = Uint8Array.from([0, 0, 0, 0, 0, 0, 0, 0]);
  const msg = Salsa20.encrypt(key, strToUintArr(data), nonce, 0, {});
  return btoa(msg) + '~';
}

export function decrypt(key, data = '') {
  if (key == null || (data?.length ?? 0) < 1 || !data.endsWith('~')) return data;
  if (data.endsWith('~')) data = data.substring(0, data.length-1);
  try {
    const str = data;
    const encrypted = strToUintArr(base64ToStr(str));

    key = strToUintArr(getEncKey(key));
    const nonce = Uint8Array.from([0, 0, 0, 0, 0, 0, 0, 0]);

    return Salsa20.decrypt(key, encrypted, nonce, 0, {});
  } catch (e) {
    console.log(e);
  }

  return data;
}

function getEncKey(key, len = 16) {
  while (key.length < len) key = `${key}${key}`;
  return key.substring(0, len);
}

function strToUintArr(str) {
  // return new TextEncoder("utf-8").encode(str);
  return Uint8Array.from(str, x => x.charCodeAt(0));
}

function base64ToStr(str = '') {
  try {
    return atob(str);
  } catch(e) {}
  return '';
}

