import { Breadcrumbs, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Container, Table, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrmbs, LoadingComp, ModalCentered } from "../comps/comps";
import { RefreshIconBtn, NextIconBtn, PrevIconBtn } from "../comps/icon-btns";
import dataSource from "./../data/offerings";
import EntitlementPage from "./entitlement-page";
import entsDS from "./../data/entitlements";

function OfferingsDetailPage(props) {
  const navigation = useNavigate();
  const { id: idFromParams } = useParams();
  const id = props.id ?? idFromParams;
  const isInModal = props.id;
  const inited = useRef(false);
  const [data, setData] = useState(dataSource.itemForId(id));
  const [isLoading, setIsLoading] = useState(false);
  const [showEnt, setShowEnt] = useState(null);

  useEffect(() => {
    if (!inited.current) {
      if (data?.id !== id) loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    await dataSource.loadList();
    setData(dataSource.itemForId(id));
    setIsLoading(false);
  }

  if (isLoading) return <LoadingComp />;

  const custTableData = [
    ['id', data?.id],
    ['Type', data?.type],
    ['Active', <Badge bg={data?.active ? 'success' : 'warning'}>{data?.activeStr}</Badge>],
    ['Fallback Entitlement', !data?.fallbackEnt ? '-' :
      <Button variant="link" size="sm" onClick={() => setShowEnt(data.fallbackEnt)}>
        {(entsDS.itemForId(data.fallbackEnt?.id, props.cust) ?? data.fallbackEnt)?.name()}
      </Button>  
    ],
    ['Fallback Entitlement (kg)', !data?.fallbackEntIdKg ? '-' :
      <Button variant="link" size="sm" onClick={() => setShowEnt(data.fallbackEntKg)}>
        {(entsDS.itemForId(data.fallbackEntKg?.id, props.cust) ?? data.fallbackEntKg)?.name()}
      </Button>
    ],
  ];

  return (
    <>
    <ModalCentered show={showEnt?.id} onHide={() => setShowEnt(null)} headerTitle={'Entitlement'}>
      <EntitlementPage id={showEnt?.id} cust={props.cust}/>
    </ModalCentered>
    <div disabled={isLoading}>
      {!isInModal &&
        <div className="filterControls">
          <span>
            <Breadcrmbs
              items={[{to: -1, title: 'Offerings'}]}
              currentTitle={data?.id}
            />
          </span>
          <span>
            <RefreshIconBtn busy={isLoading} onRefresh={loadData}/>
          </span>
        </div>
      }
      <div>
        <Container><Row><Col>
        <Table striped bordered>
          <tbody>
            {custTableData.map(val =>
              <tr key={val[0]}>
                <td>{val[0]}</td>
                <td>{val[1]}</td>
              </tr>
            )}
          </tbody>
        </Table>

        {data?.offerings?.map(offering =>
          <div key={offering.id} style={{paddingTop: 4}}>
            <hr/>
            <h5>{offering.name()} {' '}
            <small><small><Badge bg={offering.active ? 'success' : 'warning'}>{offering.active ? 'active' : 'inactive'}</Badge></small></small>
            </h5>
            <div>Name (ru): {offering.name('ru')}</div>
            <div>Entitlement:
              <Button variant="link" size="sm" onClick={() => setShowEnt(offering.ent)}>
                {(entsDS.itemForId(offering.ent?.id, props.cust) ?? offering.ent)?.name()}
              </Button>
            </div>
            <div style={{paddingTop: 8}}></div>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Cust saves</th>
                  <th>Recurring</th>
                  <th>Prod</th>
                </tr>
              </thead>
              <tbody>
                {offering.payopts?.map(payopt =>
                  <tr key={payopt.month}>
                    <td>{payopt.month}</td>
                    <td>{payopt.price}</td>
                    <td>{payopt.curr}</td>
                    <td>{payopt.custSaves ?? '-'}</td>
                    <td>{payopt.recurringStr}</td>
                    <td>
                      {payopt.prodApple &&    <li>{payopt.prodApple} <small>(Apple)</small></li>}
                      {payopt.prodAndroid &&  <li>{payopt.prodAndroid} <small>(Android)</small></li>}
                      {payopt.prodWeb &&      <li>{payopt.prodWeb} <small>(Web)</small></li>}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Col></Row></Container>
      </div>
    </div>
    </>
  );
}

export default OfferingsDetailPage;
