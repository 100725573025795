import { UsageItemId, UsageItem, UsageByKey } from "./model";

export class UsageToChart {
  /**
   * @param {[UsageItem]} items 
   */
  constructor(items, {excludeKeys} = {}) {
    this.items = items ?? [];
    this.names = [''];
    this.objs = [];
    this.excludeKeys = new Set(excludeKeys);

    this.names.pop();
  }

  transform() {}
}

const _getChild = (item = new UsageItem()) => item.business;

export class UsageToChart1 extends UsageToChart {
  transform(getChild = _getChild, {sortFn} = {}) {
    const names = new Set();
    const keys = new Set();
    this.items.forEach(item => getChild(item)?.keys?.forEach(key => key && keys.add(key)));

    const arr = [];
    let prevDay = 999999;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const day = item.id.day;
      const obj = {
        name: (day > prevDay ? day : item.id.monthStr) + '',
        nameLong: item.id.dateStr,
      };
      keys.forEach(key => {
        const child = getChild(item);
        if (child) {
          if (this.excludeKeys.has(key)) return;
          const name = child.getName(key);
          obj[name] = child.valueForKey(key) ?? 0;
          names.add(name);
        } else {
          obj[key] = 0;
        }
      });
      arr.push(obj);
      prevDay = day;
    }

    this.names = Array.from(names);
    this.names.sort(sortFn);
    this.objs = arr;
  }
}




