import { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrmbs, LoadingComp } from "../comps/comps";
import { RefreshIconBtn } from "../comps/icon-btns";
import dataSource from "./../data/entitlements";

function EntitlementPage(props) {
  const navigation = useNavigate();
  const { id: idFromParams } = useParams();
  const id = props.id ?? idFromParams;
  const isInModal = props.id;
  const inited = useRef(false);
  const [data, setData] = useState(dataSource.itemForId(id, props.cust));
  const [isLoading, setIsLoading] = useState(false);
  const [showOrig, setShowOrig] = useState(false);

  useEffect(() => {
    if (!inited.current) {
      if (data?.id !== id || dataSource.data.length < 1) loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    await dataSource.loadList();
    setData(dataSource.itemForId(id, props.cust));
    setIsLoading(false);
  }

  if (isLoading) return <LoadingComp />;

  const original = dataSource.itemForId(id);
  const overridden = original !== dataSource.itemForId(id, props.cust);

  const custTableData = [
    ['id', data?.id],
    ['Name', data?.name()],
    ['Name (ru)', data?.name('ru')],
    ['Maxes', <>
      {data?.maxesStr && data.maxesStr.map(val =>
        <li key={val}>{val}</li>
      )}
      {data.id === 'free' &&
        <li style={{paddingTop: 8}}>
          "rest": 10, "def": 20 (<small>For the customers registered after 24.06.2023</small>)
        </li>
      }
    </>
    ],
    ['Desc', data?.desc()?.map(d => <li key={d}>{d}</li>)],
    ['Desc (ru)', data?.desc('ru')?.map(d => <li key={d}>{d}</li>)],
  ];

  return (
    <div disabled={isLoading}>
      {!isInModal &&
        <div className="filterControls">
          <span>
            <Breadcrmbs
              items={[{to: -1, title: 'Entitlements'}]}
              currentTitle={data?.name()}
            />
          </span>
          <span>
            <RefreshIconBtn busy={isLoading} onRefresh={loadData}/>
          </span>
        </div>
      }
      <Container>
      {overridden &&
        <Row><Col style={{paddingBottom: 8}}>
          <Form>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label={showOrig ? 'Show the overridden ent' : 'Show the original ent'}
              checked={showOrig}
              onChange={() => {
                setData(dataSource.itemForId(id, (!showOrig ? null : props.cust)));
                setShowOrig(!showOrig);
              }}
            />
          </Form>
        </Col></Row>
      }
      <Row><Col>
        <Table striped bordered>
          <tbody>
            {custTableData.map(val =>
              <tr key={val[0]}>
                <td>{val[0]}</td>
                <td>{val[1]}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col></Row></Container>
    </div>
  );
}

export default EntitlementPage;
