import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { LoadingComp } from "../../comps/comps";
import { GeneralData } from "../../data/general";
import custData, { Customer, IndividualFixedPriceOffering, IndividualFixedPriceOfferingItem } from "../../data/customers";
import offeringsData from "../../data/offerings";
import entsData from "../../data/entitlements";

function EditDefaultWebOfferings({custEmail, onDone}) {
  const [customer] = useState(custData.itemForEmail(custEmail));
  const inited = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defWebOfferings, setDefWebOfferings] = useState(customer.preferedWebOfferingsId);
  const individualFixedPriceWebOffering = useRef(customer?.individualFixedPriceWebOfferingOrDefault?.clone());

  useEffect(() => {
    if (!inited.current) loadData();
    inited.current = true;
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    if (offeringsData.data.length < 1) await offeringsData.loadList();
    if (entsData.data.length < 1) await entsData.loadList();
    setIsLoading(false);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    let individualFixed = individualFixedPriceWebOffering?.current?.json ?? null;
    if (defWebOfferings !== Customer.individualFixedPriceWebOfferingKey) individualFixed = null;

    const ok = await custData.updateDefaultWebOfferings(customer.id, {
      prefered_web_offerings: defWebOfferings === '_none_' ? null : defWebOfferings,
      individual_fixed_price_web_offering: individualFixed,
    });

    setIsLoading(false);
    if (ok) onDone && onDone();
    else alert('Error!');
  }

  const webOfferings = offeringsData.data.filter(data => data.type === 'web');

  if (isLoading) return <LoadingComp />;
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="fc.preferedWebOfferings">
        <Form.Label>Select prefered web offerings for "{customer?.name}"</Form.Label>
        <Form.Control
          as="select"
          value={defWebOfferings}
          onChange={e => {
            setDefWebOfferings(e.target.value);
          }}
        >
          <option value="_none_">None</option>
          <option value={Customer.individualFixedPriceWebOfferingKey}>Individual fixed price</option>
          {webOfferings.map(item =>
            <option key={item.id} value={item.id}>
              {item.id}
            </option>
          )}
        </Form.Control>
      </Form.Group>

      {defWebOfferings === Customer.individualFixedPriceWebOfferingKey && 
        <>
          <hr/>
          <IndividualFixedPriceOfferingView
            obj={individualFixedPriceWebOffering.current}
            onChange={(val) => individualFixedPriceWebOffering.current = val}
            customer={customer}
            />
        </>
      }

      <Button variant="primary" type="submit">Submit</Button>
    </Form>
  );
}

export default EditDefaultWebOfferings;


/**
 * 
 * @param {{obj: IndividualFixedPriceOffering, customer: Customer}} param0
 * @returns 
 */
function IndividualFixedPriceOfferingView({obj, onChange, customer}) {
  const [data, setData] = useState(obj);

  const upd = (fn) => {
    fn && fn();
    if ((data.extraOfferings?.length ?? 0) < 1) delete data.json.extra_offerings;
    setData(data.clone());
    onChange && onChange(data);
  }

  const addNewOffering = async () => {
    upd(() => {
      const arr = data.json.extra_offerings ?? [];
      arr.push(IndividualFixedPriceOfferingItem.withDefault(customer?.country).json);
      data.json.extra_offerings = [...arr];
    });
  }

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.fallbackEnt">
        <Form.Label column sm="3">Fallback Entitlement</Form.Label>
        <Col>
          <Form.Control as="select" value={data.fallbackEnt} onChange={e => upd(() => data.fallbackEnt = e.target.value)}>
            {entsData.data.map(item => <option key={item.id} value={item.id}>{item.nameAll}</option>)}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.curr">
        <Form.Label column sm="3">Currency</Form.Label>
        <Col>
          <Form.Control as="select" value={data.curr} onChange={e => upd(() => data.curr = e.target.value)}>
            {GeneralData.currencies.map(curr => <option key={curr} value={curr}>{curr}</option>)}
          </Form.Control>
        </Col>
      </Form.Group>
      <hr/>
      <IndividualFixedPriceOfferingOptionView
        data={data}
        onChange={(data) => {upd()}}
      />
      {data.extraOfferings?.map((item, index) =>
        <span key={item.id}>
          <hr/>
          <IndividualFixedPriceOfferingOptionView
            data={item}
            onChange={e => {upd(() => data.json.extra_offerings[index] = e.json)}}
            onDel={e => {upd(() => data.json.extra_offerings.splice(index, 1))}}
          />
        </span>
      )}
      <hr/>
      <Button variant="secondary" onClick={addNewOffering}>+</Button>
      {' '}
    </>
  );
}

function IndividualFixedPriceOfferingOptionView({data, onChange, onDel}) {
  const upd = (fn) => {
    fn && fn();
    onChange && onChange(data);
  }

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.name">
        <Form.Label column sm="3">Name</Form.Label>
        <Col>
          <Form.Control value={data.name} onChange={e => upd(() => data.name = e.target.value)}>
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.nameRu">
        <Form.Label column sm="3">Name (ru)</Form.Label>
        <Col>
          <Form.Control value={data.nameRu} onChange={e => upd(() => data.nameRu = e.target.value)}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.ent">
        <Form.Label column sm="3">Entitlement</Form.Label>
        <Col>
          <Form.Control as="select" value={data.ent} onChange={e => upd(() => data.ent = e.target.value)}>
            {entsData.data.map(item => <option key={item.id} value={item.id}>{item.nameAll}</option>)}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.priceFor1Month">
        <Form.Label column sm="3">Price/month</Form.Label>
        <Col>
          <Form.Control value={data.priceFor1Month} onChange={e => upd(() => data.priceFor1Month = e.target.value)}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="fc.fixed.hasDiscFor12Months">
        <Form.Label column sm="3">
          {onDel && <Button variant="danger" onClick={onDel}>-</Button>}
        </Form.Label>
        <Col>
          <Form.Check 
            type="checkbox"
            id="hasDiscFor12Months"
            label="Discount for 12 months"
            checked={data.hasDiscFor12Months}
            onChange={e => upd(() => data.hasDiscFor12Months = !data.hasDiscFor12Months)}/>
        </Col>
      </Form.Group>
    </>
  );
}


