import api from "./../api/api";
import * as helper from './../core/helper';

const limit = 5;

export class BillingEvents {
  #allLoaded;

  constructor(custid) {
    this.custid = custid;
    this.data = [new BillingEvent()];
    this.data.pop();
  }

  loadedPage() { return Math.ceil(this.data.length/limit); }
  allPagesLoaded() { return this.#allLoaded ?? (this.loadedPage() * limit > this.data.length); }

  reset() {
    this.data = [];
    this.#allLoaded = false;
  }

  getPage(page = 1) {
    let i = (page - 1) * limit;
    const to = page * limit;
    const res = [];
    for (i; i < to; i++) {
      const item = this.data[i];
      if (item) res.push(item);
    }
    return res;
  }

  async loadList(page = 1) {
    try {
      if (!this.custid) throw new Error('No customer');
      if (page <= this.loadedPage() || this.allPagesLoaded()) throw new Error('Nothing to load');

      let path = `/bof/customers/${this.custid}/billing/events?`;
      if (this.data.length > 0) {
        const last = this.data[this.data.length-1];
        path += 'start_after_sec=' + last.sortd._seconds;
        path += '&start_after_nano=' + last.sortd._nanoseconds;
      }
      const resp = await api.inst.get(path);
      resp.data?.forEach(element => {
        const item = new BillingEvent(element);
        this.data.push(item);
      });

      if (resp.data && resp.data.length < 1)
        this.#allLoaded = true;
    } catch(e) {
      // console.error(e);
    }
    return this.getPage(page);
  }
}

export class BillingEvent {
  #crd = new Date();
  #key = '';
  #test = false;

  constructor(json) {
    this.update(json);
  }

  update(json) {
    this.json = json ?? {};

    this.#crd = helper.dtFromFirestoreTimestamp(this.json.crd);
    this.#key = JSON.stringify(this.sortd ?? {id: Math.random()});
    this.#test = this.json.test === 1 || this.json.test === true || this.json.test === '1' || this.json.test === 'true';
  }

  get test() { return this.#test }
  get key() { return this.#key }
  get ok() { return this.json.ok }
  get type() { return this.json.type }
  get msg() { return this.json.msg }
  get detail() { return this.json.detail }

  get sortd() { return this.json.crd }
  get crd() { return this.#crd }
  get crdStr() { return helper.dtToHumanStr(this.crd) ?? '' }

  get executedBofUser() { return this.json?.detail?.executedBofUser }
}



