import * as helper from './../../../core/helper';

export class AbstractType {
  #all;
  #displayName;
  constructor(name = '', getAll = () => null) {
    this.name = name?.trim();
    this.getAll = getAll;
    this.#displayName = name[0].toUpperCase() + name.substring(1);
  }
  get displayName() { return this.#displayName }
  /**
   * @return {[AbstractType]}
   */
  get all() { return (this.getAll && this.getAll()) ?? [] }
  withName(name) {
    const arr = this.all;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === name) return arr[i];
    }
    return null;
  }
}

export class DataType extends AbstractType {
  static month = new DataType('month');
  static day = new DataType('day');
  static hour = new DataType('hour');
  static ALL = [DataType.month, DataType.day, DataType.hour];
  constructor(name = 'day') {super(name, () => DataType.ALL)}
  get inSec() {
    if (this.name === DataType.month.name) return 31 * 24 * 60 * 60;
    if (this.name === DataType.day.name) return 24 * 60 * 60;
    if (this.name === DataType.hour.name) return 60 * 60;
    return -Math.random();
  }
}

export class EnvType extends AbstractType {
  static test = new EnvType('test');
  static prod = new EnvType('prod');
  static ALL = [EnvType.test, EnvType.prod];
  constructor(name = 'prod') {super(name, () => EnvType.ALL)}
}

export class PurchType extends AbstractType {
  static all = new PurchType('all');
  static purchase = new PurchType('purchase');
  static renew = new PurchType('renew');
  static refund = new PurchType('refund');
  static cancel = new PurchType('cancel');
  static ALL = [PurchType.all, PurchType.purchase, PurchType.renew, PurchType.refund, PurchType.cancel];
  constructor(name = 'all') {super(name, () => PurchType.ALL)}
}

export class MonthType extends AbstractType {
  static all = new MonthType('all');
  static single = new MonthType('msingle');
  static month1 = new MonthType('m1');
  static month3 = new MonthType('m3');
  static month6 = new MonthType('m6');
  static month12 = new MonthType('m12');
  static singleRecurring = new MonthType('msingle_recur');
  static month1Recurring = new MonthType('m1_recur');
  static month12Recurring = new MonthType('m12_recur');
  static ALL = [MonthType.all, MonthType.single, MonthType.month1, MonthType.month3, MonthType.month6, MonthType.month12, MonthType.singleRecurring, MonthType.month1Recurring, MonthType.month12Recurring];
  constructor(name = 'all') {super(name, () => MonthType.ALL)}
}

export class CurrType extends AbstractType {
  static USD = new CurrType('usd');
  static KGS = new CurrType('kgs');
  static ALL = [CurrType.USD, CurrType.KGS];
  constructor(name = 'usd') {super(name?.toLocaleLowerCase(), () => CurrType.ALL)}
  get displayName() { return this.name.toUpperCase() }
}

export class ItemResultGroup {
  /**
   * @param {ItemResultGroup} prev
   * @param {{date: Date, children: [ItemResult], dataType: DataType, env: EnvType, from: Date, to: Date, currType: CurrType}}
   */
  constructor(prev, {date, children, dataType, env, from, to, currType} = {}) {
    this.prev = prev;
    this.date = date ?? new Date();
    this.children = children ?? [];
    this.dataType = dataType;
    this.env = env;
    this.from = from;
    this.to = to;
    this.currType = currType;
    
    this.dtNameLong = '';
    this.dtName = '';

    this.#update();
  }

  /**
   * @param {PurchType} purchType
   * @param {MonthType} monthType
   */
  sumItem(purchType, monthType) {
    this.#normItems();
    return this.#sumItemMap.get(`${purchType?.name || purchType}.${monthType?.name || monthType}`);
  }

  #sumItemMap = new Map([['', new Item()]]);
  #childrenMap = new Map([['', [new ItemResult()]]]);

  #normItems() {
    if (this.#sumItemMap.size > 0 || this.#childrenMap.size > 0) return;
    this.children?.forEach(res => {
      const purchTypeName = res.purchType?.name;
      const monthTypeName = res.monthType?.name;
      const key = `${purchTypeName}.${monthTypeName}`;

      if (!this.#childrenMap.has(key)) this.#childrenMap.set(key, []);
      this.#childrenMap.get(key).push(res);

      if (!this.#sumItemMap.has(key)) this.#sumItemMap.set(key, new Item(null, res.monthType, res.purchType));
      const item = this.#sumItemMap.get(key);
      item.add(res.item);
    });
  }

  #update() {
    this.#sumItemMap.clear();
    this.#childrenMap.clear();

    const dt = this.date;
    const prevDt = this.prev?.date ?? dt;
    const dtArr = dt.toUTCString().split(' '); // Wed, 04 Sep 2022 10:24:41 GMT
    // const dtWW = dtArr[0].split(',')[0];
    const dtDD = dtArr[1].startsWith('0') ? dtArr[1].substring(1, 2) : dtArr[1];
    const dtMM = dtArr[2];
    const dtYY = dtArr[3];
    const dtHH = dtArr[4].split(':')[0];

    if (this.dataType?.name === DataType.month.name) {
      this.dtNameLong = `${dtMM} ${dtYY}`;
      if (prevDt.getUTCFullYear() !== dt.getUTCFullYear()) this.dtName = dtYY;
      else this.dtName = dtMM;
    }
    else if (this.dataType?.name === DataType.day.name) {
      this.dtNameLong = `${dtDD} ${dtMM} ${dtYY}`;
      if (prevDt.getUTCMonth() !== dt.getUTCMonth()) this.dtName = dtMM;
      else this.dtName = dtDD;
    }
    else {
      this.dtNameLong = `${dtDD} ${dtMM} ${dtYY} ${dtHH}`;
      if (prevDt.getUTCDate() !== dt.getUTCDate()) this.dtName = `${dtDD}${dtMM.substring(0, 1)}`;
      else this.dtName = dtHH;
    }
  }
}

export class ItemResult {
  /**
   * 
   * @param {Item} item
   * @param {{parent: AbstractItem, totals: Totals, monthType: MonthType, purchType: PurchType}}
   */
  constructor(item, {parent, totals, monthType, purchType} = {}) {
    this.item = item;
    this.parent = parent;
    this.totals = totals;
    this.monthType = monthType;
    this.purchType = purchType;
  }
}

export class ItemTotal {
  constructor(json) {
    this.update(json);
  }

  update(json) {
    this.json = json || {};
    this.average = this.json.avg ?? 0;
    this.positive = this.json.pos ?? 0;
    this.negative = this.json.neg ?? 0;
    this.canceled = this.json.cnl ?? 0;

    this.countAvg = this.json.cnt_all ?? 0;
    this.countPositive = this.json.cnt_pos ?? 0;
    this.countNegative = this.json.cnt_neg ?? 0;
    this.countCanceled = this.json.cnt_cnl ?? 0;
  }

  /**
   * 
   * @param {ItemTotal} other 
   */
  add(other) {
    this.average += other?.average ?? 0;
    this.positive += other?.positive ?? 0;
    this.negative += other?.negative ?? 0;
    this.canceled += other?.canceled ?? 0;

    this.countAvg += other?.countAvg ?? 0;
    this.countPositive += other?.countPositive ?? 0;
    this.countNegative += other?.countNegative ?? 0;
    this.countCanceled += other?.countCanceled ?? 0;
  }
}

export class Item extends ItemTotal {
  /**
   * @param {MonthType} monthType
   * @param {PurchType} purchType
   */
  constructor(json, monthType, purchType) {
    super(json);
    this.monthType = monthType;
    this.purchType = purchType;
  }
}

// `${_type}.${_month}.${curr}.${negpos}`
export class Totals {
  #env = new EnvType();
  #map = new Map([['_dummy', new Item()]]);
  #itemsByPurchCurr = new Map([['_dummy', [new Item()]]]);
  #itemsByCurr = new Map([['_dummy', [new Item()]]]);
  #purchTypes = [new PurchType()];
  #monthsByPurchType = new Map([['_dummy', [new MonthType()]]]);
  #currsByMonthPurchType = new Map([['_dummy', [new CurrType()]]]);

  constructor(env, json) {
    this.#env = env;
    this.update(json);
  }

  update(json) {
    this.#map.clear();
    this.#itemsByPurchCurr.clear();
    this.#itemsByCurr.clear();
    this.#purchTypes = [];
    this.#monthsByPurchType.clear();
    this.#currsByMonthPurchType.clear();
    this.json = json ?? {};

    const pushTo = (map, key, val) => {
      if (!map.has(key)) map.set(key, []);
      map.get(key).push(val);
    }

    const keys = Object.keys(this.json);
    keys?.forEach(key => {
      const purchType = new PurchType(key);
      this.#purchTypes.push(purchType);
      (Object.keys(this.json[key]))?.forEach(key => {
        const monthType = new MonthType(key);
        pushTo(this.#monthsByPurchType, purchType.name, monthType);
        (Object.keys(this.json[purchType.name][key]))?.forEach(key => {
          const currType = new CurrType(key);
          const mapKey = `${purchType.name}.${monthType.name}`;
          pushTo(this.#currsByMonthPurchType, mapKey, currType);

          const item = new Item(this.json[purchType.name][monthType.name][currType.name], monthType, purchType);
          this.#map.set(`${mapKey}.${currType.name}`, item);
          pushTo(this.#itemsByPurchCurr, `${purchType.name}.${currType.name}`, item);
          pushTo(this.#itemsByCurr, currType.name, item);
        });
      });
    });
  }

  get env() { return this.#env }

  getPurchTypes() { return this.#purchTypes ?? [] }
  getMonthTypes(purchType = new PurchType()) { return this.#monthsByPurchType.get(purchType.name) ?? [] }
  getCurrTypes(purchType = new PurchType(), monthType = new MonthType()) { return this.#currsByMonthPurchType.get(`${purchType.name}.${monthType.name}`) ?? [] }

  getItem({purchType = new PurchType(), monthType = new MonthType(), currType = new CurrType()} = {}) {
    if (typeof purchType === 'string') purchType = new PurchType(purchType);
    if (typeof monthType === 'string') monthType = new MonthType(monthType);
    if (typeof currType === 'string') currType = new CurrType(currType);
    return this.#map.get(`${purchType?.name}.${monthType?.name}.${currType?.name}`);
  }

  getItems({purchType, monthType, currType = new CurrType()} = {}) {
    if (typeof purchType === 'string') purchType = new PurchType(purchType);
    if (typeof monthType === 'string') monthType = new MonthType(monthType);
    if (typeof currType === 'string') currType = new CurrType(currType);
    if (purchType && monthType) {
      const item = this.getItem({purchType, monthType, currType});
      if (!item) return [];
      return [item];
    }
    if (!purchType && !monthType) {
      return this.#itemsByCurr.get(currType?.name) ?? [];
    }
    return this.#itemsByPurchCurr.get(`${purchType?.name}.${currType?.name}`) ?? [];
  }
}

export class AbstractItem {
  #sortIndex = 0;
  #crd = new Date();
  #upd = new Date();
  #totals = new Map([['_dummy', new Totals()]]);
  #envs = [new EnvType()];

  /**
   * 
   * @param {Object} json 
   * @param {AbstractItem} parent 
   */
  constructor(json, parent) {
    this.parent = parent;
    if (json) this.update(json);
  }

  update(json) {
    this.#totals.clear();
    this.#envs = [];
    this.json = json ?? {};

    this.#crd = helper.dtFromFirestoreTimestamp(this.json.crd);
    this.#upd = helper.dtFromFirestoreTimestamp(this.json.upd);
    this.#sortIndex = this.crd?.getTime() ?? 0;

    const keys = Object.keys(this.json);
    keys?.forEach(key => {
      if (key.startsWith('totals_')) {
        const env = new EnvType(key.split('_')[1]);
        this.#envs.push(env);
        this.#totals.set(env.name, new Totals(env, this.json[key]));
      }
    });
  }

  get id() { return this.json?.id }
  get sortIndex() { return this.#sortIndex }

  get crd() { return this.#crd }
  get crdStr() { return helper.dtToHumanStr(this.crd) ?? '' }

  get upd() { return this.#upd }
  get updStr() { return helper.dtToHumanStr(this.upd) ?? '' }

  get envs() { return this.#envs }
  getTotalsOfEnv(env = new EnvType()) { return this.#totals.get(env.name) }
}

export class Monthly extends AbstractItem {
  update(json) {
    super.update(json);
  }
}

export class Daily extends AbstractItem {
  update(json) {
    super.update(json);
  }
}

export class Hourly extends AbstractItem {
  constructor(json, parent) {
    super();
    this.parent = parent;
    this.eventsByEnv = new Map([['_dummy', [new Event()]]]);
    this.update(json);
  }

  update(json) {
    super.update(json);
    this.eventsByEnv.clear();

    const keys = Object.keys(this.json.events ?? {});
    keys?.forEach(envName => {
      const arr = [];
      this.eventsByEnv.set(envName, arr);
      const events = this.json.events[envName];
      const ids = Object.keys(events);
      ids?.forEach(id => {
        arr.push(new Event(id, events[id]));
      });
    });
  }
}

export class Event {
  constructor(id, json) {
    this.json = json ?? {};

    this.#id = id;
    this.#amount = this.json['amount'];
    this.#crd = helper.dtFromFirestoreTimestamp(this.json['crd']);
    this.#curr = this.json['curr'];
    this.#currType = new CurrType(this.#curr);
    this.#custId = (this.json['cust_event']?.split('/') ?? [''])[0];
    this.#custEventId = (this.json['cust_event']?.split('/') ?? ['', ''])[1];
    this.#month = this.json['month'];
    this.#type = new PurchType(this.json['type']);
  }

  #id = '';
  #amount = 0;
  #crd = new Date();
  #curr = '';
  #currType = new CurrType();
  #custId = '';
  #custEventId = '';
  #month = 0;
  #type = new PurchType();

  get id() { return this.#id }
  get amount() { return this.#amount }
  get curr() { return this.#curr }
  get currType() { return this.#currType }
  get custId() { return this.#custId }
  get custEventId() { return this.#custEventId }
  get month() { return this.#month }
  get type() { return this.#type }

  get crd() { return this.#crd }
  get crdStr() { return helper.dtToHumanStr(this.crd) ?? '' }
}




