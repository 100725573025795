import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import * as CountryList from "country-list";
import * as modelu from "../../../data/report/usage/model";
import { Usages, UsageToChart1 } from "../../../data/report/usage/usages";
import { GeneralData } from "../../../data/general";
import { ChartGeneralTooltip } from "./usage-common-chart";

const colors = GeneralData.chartColors;

/**
 * 
 * @param {{loading: Boolean, ds: Usages}} param0 
 * @returns 
 */
export function UsageBycountryBustypeChart({loading, ds}) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [countries, setCountries] = useState(['All']);
  const [country, setCountry] = useState('All');
  const [lastItems, setLastItems] = useState([]);
  
  useEffect(() => {
    const updateData = (items) => {
      if (!items || !ds?.hasListener(updateData)) return;
      const trans = new UsageToChart1(items, {excludeKeys: ['all']});
      trans.transform((item) => item.businesNumByType_byCountry);
      setCountries(['All', ...trans.names]);
      setLastItems(items);
    }
    ds?.addListener(updateData);
    updateData(ds?.getItems());
    return () => ds?.removeListener(updateData);
  }, []);

  useEffect(() => {
    const trans = new UsageToChart1(lastItems, {excludeKeys: ['all']});
    if (country === 'All') {
      trans.transform((item) => item.businesNumByType);
    } else {
      trans.transform((item) => item._businesNumByType_byCountry.childForKey(country, {shouldCalcAll: true}));
    }
    setData(trans.objs);
    setKeys(trans.names);
  }, [country, lastItems]);

  return (
    <Card style={{zIndex: 1}}>
      <Card.Body>
        <Card.Title>
          <span disabled={loading} className="filterControls">
            <span>Business Type</span>
            <span>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="country" size="sm">{CountryList.getName(country) ?? country}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {countries.map(code => 
                    <Dropdown.Item key={code+'c'} onClick={() => setCountry(code)}>{CountryList.getName(code) ?? code}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </span>
        </Card.Title>
      </Card.Body>
      <div style={styles.chart}>
        <ResponsiveContainer width="100%" aspect={2} maxHeight={360}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="1 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              content={<ChartGeneralTooltip />}
              // formatter={(value, name, props) => [`${value} - ${name}`]}
              // labelFormatter={(label, props) => <small>{props[0]?.payload?.nameLong}</small>}
              />
            <Legend />
            {keys?.map((key, index) =>
              <Line key={key} dataKey={key} name={key} type="monotone" stroke={colors[index]} animationDuration={800} />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

const styles = {
  chart: {
    padding: 8,
  },
}















