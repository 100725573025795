import { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Dropdown, Row } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import * as helper from './../../core/helper';
import * as modelu from "../../data/report/usage/model";
import ds from "./../../data/report/usage/usages";
import { RefreshIconBtn } from "../../comps/icon-btns";
import { UsageCommonChart, UsageBycountryBustypeChart, UsageKeySelChart } from "./comps";

const defFromDate = () => helper.date({utc: false, addedMonth: -1, startOfDay: true});
const defToDate = () => helper.date({utc: false, addedDay: 1, startOfDay: true});

const cache = {
  from: defFromDate(),
  to: defToDate(),
  refreshCount: 0,
  loadKey: '',
  lastLoadedDate: new Date(),
}

function UsageReportsPage() {
  const [from, setFrom] = useState(cache.from);
  const [to, setTo] = useState(cache.to);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(cache.refreshCount);
  const anyLoaded = useRef(false);

  useEffect(() => {
    cache.from = from;
    cache.to = to;
    cache.refreshCount = refresh;
  }, [from, to, refresh]);

  useEffect(() => {
    const key = `$${from}; ${to} [refresh]${refresh}`;
    let force = key.substring(key.indexOf('[refresh]')) !== cache.loadKey.substring(cache.loadKey.indexOf('[refresh]'));
    if ((new Date()).getTime() - cache.lastLoadedDate.getTime() > 5 * 60 * 1000) force = true;

    if (anyLoaded.current && !force && (key === cache.loadKey || loading)) return;
    cache.lastLoadedDate = new Date();
    cache.loadKey = key;
    anyLoaded.current = true;
    setLoading(true);

    (async () => {
      await ds.def.fetchData({from, to, force})
      setLoading(false);
    })();
  }, [from, to, loading, refresh]);

  return (
    <>
    <Container>
      <Row>
        <Col lg={12}>
          <div disabled={loading} className="filterControls">
            <span>
              <DateTimePicker disabled={loading} value={from} maxDate={to} onChange={newDate => { setFrom(newDate ?? defFromDate()) }} />
            </span>
            <span>
              <DateTimePicker disabled={loading} value={to} minDate={from} onChange={newDate => { setTo(newDate ?? defToDate()); }} />
            </span>
            <span>
              <RefreshIconBtn size="small" busy={loading} onRefresh={() => setRefresh(refresh+1)} />
            </span>
          </div>
        </Col>
      </Row>
      <Row><Col><hr/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageCommonChart loading={loading} ds={ds.def} getChild={item => item.business} title='Business' />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageBycountryBustypeChart loading={loading} ds={ds.def} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageCommonChart loading={loading} ds={ds.def} getChild={item => item.module} title='Module' />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageCommonChart loading={loading} ds={ds.def} getChild={item => item.opened} title='App Launch' />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageCommonChart loading={loading} ds={ds.def} getChild={item => item.version} title='Version' />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12} style={{zIndex: 1}}>
          <UsageCommonChart loading={loading} ds={ds.def} getChild={item => item.osFlat} title='OS' excludeKeys={['all']} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
      <Row>
        <Col sm={12}>
          <UsageKeySelChart loading={loading} ds={ds.def} getChild={item => item.resouces} title='Resources' keysSortFn={(a, b) => b.length - a.length} />
        </Col>
      </Row>
      <Row><Col><br/></Col></Row>
    </Container>
    </>
  );
}

export default UsageReportsPage;



