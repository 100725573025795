
export class Cache {
  constructor(id = 'default') {
    this.id = id;
  }

  #byKey = new Map();

  ver() { return localStorage.getItem('__cache_version') ?? '1' }
  normKey(key) { return `__cache_${this.ver()}_${this.id}_${key}` }

  static reset() {
    const ver = parseInt(this.ver());
    localStorage.setItem('__cache_version', `${ver + 1}`);
  }

  /**
   * 
   * @param {string} key 
   * @param {any} val 
   */
  write(key, val) {
    key = this.normKey(key);
    this.#byKey.set(key, val);
    
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    }
    localStorage.setItem(key, `${val}`);
  }

  /**
   * 
   * @returns {string|object}
   */
  read(key) {
    key = this.normKey(key);
    let val = this.#byKey.get(key);
    if (!val) {
      val = this.#normRead(localStorage.getItem(key));
      if (val) this.#byKey.set(key, val);
    }
    return val;
  }
  
  #normRead(val) {
    try {
      val = JSON.parse(val);
    } catch(e) {}
    return val;
  }
}
