import api from "./../api/api";
import { BillingEvents } from "./cust-billing-events";
import { Entitlement } from "./entitlements";
import { OfferingsVo } from "./offerings";
import * as helper from './../core/helper';
import * as model from './report/payment/model';
import { GeneralData } from "./general";
import { CustDeviceInfo } from "./cust-dinfo";

const limit = 10;
class Customers {
  #allLoaded;
  #billingEventsByCust = new Map();

  constructor() {
    this.data = [new Customer()];
    this.data.pop();
    this.byEmail = new Map();
    this.byEmail.set('dummy', new Customer());
    this.byEmail.clear();
    this.byId = new Map([['dummy', new Customer()]]);
    this.byId.clear();
  }

  /**
   * @return {Customer}
   */
  itemForEmail(email) { return this.byEmail.get(email); }

  /**
   * @return {Customer}
   */
   itemForId(id) { return this.byId.get(id); }

  /**
   * @return {BillingEvents}
   */
  billingEventsFor(custid) {
    if (!this.#billingEventsByCust.has(custid)) this.#billingEventsByCust.set(custid, new BillingEvents(custid));
    return this.#billingEventsByCust.get(custid);
  }
  billingEventsForEmail(email) { return this.billingEventsFor(this.itemForEmail(email)?.id) }

  loadedPage() { return Math.ceil(this.data.length/limit); }
  allPagesLoaded() { return this.#allLoaded ?? (this.loadedPage() * limit > this.data.length); }

  reset() {
    this.data = [];
    this.#allLoaded = false;
  }

  getPage(page = 1) {
    let i = (page - 1) * limit;
    const to = page * limit;
    const res = [];
    for (i; i < to; i++) {
      const cust = this.data[i];
      if (cust) res.push(cust);
    }
    return res;
  }

  async loadList(page = 1, {countryCode, until_dt, paidCust} = {}) {
    try {
      if (page <= this.loadedPage() || this.allPagesLoaded())
        return this.getPage(page);

      let path = '/bof/customers?';
      if (this.data.length > 0) {
        const last = this.data[this.data.length-1];
        path += 'start_after_sec=' + last.sortd._seconds;
        path += '&start_after_nano=' + last.sortd._nanoseconds;
      }
      if (countryCode) {
        path += '&countryCode=' + countryCode;
      }
      if (until_dt) {
        path += '&until_dt=' + until_dt;
      }
      if (paidCust) {
        path += '&paidCust=' + paidCust;
      }
      const resp = await api.inst.get(path);
      resp.data?.forEach(element => {
        const cust = new Customer(element);
        this.data.push(cust);
        if (cust.email) this.byEmail.set(cust.email, cust);
        if (cust.id) this.byId.set(cust.id, cust);
      });

      if (resp.data && resp.data.length < 1)
        this.#allLoaded = true;
    } catch(e) {}
    return this.getPage(page);
  }

  async reloadCustWithEmail(email) {
    if (!email) return;
    try {
      const resp = await api.inst.get('/bof/customers/email/' + email);
      if (!resp?.data) return;
      let cust = this.byEmail.get(email);
      if (!cust) {
        cust = new Customer();
        this.byEmail.set(email, cust);
        this.byId.set(cust.id, cust);
      }
      cust.update(resp.data);
    } catch(e) {}
  }

  async updateDefaultWebOfferings(custId, {prefered_web_offerings, individual_fixed_price_web_offering}) {
    if (!custId) return false;
    try {
      const path = `/bof/customers/${custId}/prefered_web_offerings`;
      const resp = await api.inst.put(path, {prefered_web_offerings, individual_fixed_price_web_offering});
      if (resp.status !== 200) return false;
    } catch(e) {
      return false;
    }
    return true;
  }

  async updatePromo(custId, {entid, dt_until}) {
    if (!custId) return false;
    try {
      const path = `/bof/customers/${custId}/promo`;
      const resp = await api.inst.put(path, {entid, dt_until});
      if (resp.status !== 200) return false;
    } catch(e) {
      return false;
    }
    return true;
  }

  async updatePreferedEnts(custId, preferedEnts) {
    if (!custId) return false;
    try {
      const path = `/bof/customers/${custId}/prefered_ents`;
      const resp = await api.inst.put(path, {preferedEnts});
      if (resp.status !== 200) return false;
    } catch(e) {
      return false;
    }
    return true;
  }
}

export class CustPurchTotals {
  #currTypes = new Map([['', [model.CurrType.KGS]]]);
  #totals = new Map([['', new model.ItemTotal()]]);
  #strs = new Map([['', ['']]]);
  
  update(json) {
    this.json = json ?? {};
    this.#currTypes.clear();
    this.#totals.clear();
    this.#strs.clear();

    const envs = Object.keys(this.json);
    envs?.forEach(env => {
      const currs = Object.keys(this.json[env]);
      currs?.forEach(curr => {
        const currType = model.CurrType.USD.withName(curr);
        if (currType) {
          const item = new model.ItemTotal(this.json[env][curr]);
          if (!this.#currTypes.has(env)) this.#currTypes.set(env, []);
          this.#currTypes.get(env).push(currType);
          this.#totals.set(`${env}.${curr}`, item);

          this.#addStr(`${env}.avg`, `${helper.finFormatAmount(item.average, {currency: currType.name})}(${item.countAvg})`);
          this.#addStr(`${env}.pos`, `${helper.finFormatAmount(item.positive, {currency: currType.name})}(${item.countPositive})`);
          this.#addStr(`${env}.neg`, `${helper.finFormatAmount(item.negative, {currency: currType.name})}(${item.countNegative})`);
        }
      });
    });
  }

  #addStr(key, val) {
    if (!this.#strs.has(key)) this.#strs.set(key, []);
    this.#strs.get(key).push(val);
  }

  get env() { return GeneralData.appEnv().name }
  get currTypes() { return this.currTypes.get(this.env) ?? [] }
  getTotalObj(curr) { return this.totals.get(`${this.env}.${curr}`) }

  get averagesStr() { return this.#strs.get(`${this.env}.avg`) ?? [] }
  get positivesStr() { return this.#strs.get(`${this.env}.pos`) ?? [] }
  get negativesStr() { return this.#strs.get(`${this.env}.neg`) ?? [] }
}

export class Customer {
  static get individualFixedPriceWebOfferingKey() { return 'individual_fixed_price_web_offering' };
  #_individualFixedPriceWebOfferingConverted;

  #creationDate = new Date();
  #lastRefreshDate = new Date();
  #myEnt = new Entitlement();
  #purchTotals = new CustPurchTotals();
  #preferedEnts;

  constructor(json) {
    this.update(json);
  }

  update(json) {
    this.#preferedEnts = null;
    this._deviceInfo = null;
    this.json = json ?? {};

    if (this.json['_individual_fixed_price_web_offering_converted'])
      this.#_individualFixedPriceWebOfferingConverted = new OfferingsVo(this.json['_individual_fixed_price_web_offering_converted']);
    
    this.#creationDate = helper.dtFromFirestoreTimestamp(this.json.creationTime);
    this.#lastRefreshDate = helper.dtFromFirestoreTimestamp(this.json.lastRefreshTime);
    this.#myEnt.update(this.json?.entitlement);
    this.#purchTotals.update(this.json?.purch_totals);
  }

  get id() { return this.json.id }
  get name() { return this.json.name }
  get email() { return this.json.email }
  get country() { return this.json.country }
  get countryName() { return this.json.countryName }
  get city() { return this.json.city }
  get timezone() { return this.json.timezone }
  get busType() { return this.json.busType ?? 'restaurant' }
  get purchTotals() { return this.#purchTotals }
  get hasAnyPurch() { return this.json?.purch_totals?.prod }
  get revcatTransferAnonymId() { return this.json.revcat_transfer_anonym }

  get preferedWebOfferingsId() { return this.json.prefered_web_offerings }
  get preferedWebOfferingsIdFull() {
    let offering = this.preferedWebOfferingsId;
    if (offering === Customer.individualFixedPriceWebOfferingKey) offering = 'cust_' + this.email + '/' + offering;
    return offering;
  }
  
  /**
   * @return {OfferingsVo}
   */
  get individualFixedPriceWebOfferingConverted() { return this.#_individualFixedPriceWebOfferingConverted }
  get individualFixedPriceWebOffering() {
    if (!this.json[Customer.individualFixedPriceWebOfferingKey]) return null;
    return new IndividualFixedPriceOffering(this.json[Customer.individualFixedPriceWebOfferingKey])
  }
  get individualFixedPriceWebOfferingOrDefault() { return this.individualFixedPriceWebOffering ?? IndividualFixedPriceOffering.withDefault(this.country) }

  get myEnt() { return this.#myEnt?.id ? this.#myEnt : null  } // { return this.myEntExpiresAfterDays > 0 ? (new Entitlement(this.json?.entitlement)) : null }
  get myEntExpiresAfterDays() { return !this.myEnt ? 0 : (this.json.myEntExpiresAfterDays ?? 0) }
  get myEntExpired() { return !this.myEnt ? false : (this.myEntExpiresAfterDays <= 0) }
  get myEntDtUntil() { return helper.dtFromUtcStr(this.json?.balance?.dt_until); }
  get myEntUntilDateIsUnlim() { return (this.myEntExpiresAfterDays ?? 0) > 73000 }
  getMyEntExpiresInStr({onlyDays = true} = {}) {
    if (this.myEntUntilDateIsUnlim) {
      const expMs = this.json?.last_purch?.inapp?.event?.expiration_at_ms;
      if (expMs) {
        try {
          const expDate = new Date(expMs);
          const diff = helper.dtDaysDiff(new Date(), expDate);
          return diff?.toFixed(3) + ' days~';
        } catch (e) {}
      }
      return '∞';
    }
    if (onlyDays) {
      if (!this.myEntExpiresAfterDays) return '';
      if (this.myEntExpiresAfterDays <= 0) return '0 days';
      return `${this.myEntExpiresAfterDays?.toFixed(3)} days`;
    }
    const arr = [];
    if (this.myEntDtUntil) {
      const dateStr = helper.dtToHumanStr(this.myEntDtUntil);
      arr.push(dateStr);
      try {
        const dateStrCustLocal = helper.dtToHumanStr(this.myEntDtUntil, this.timezone);
        if (dateStrCustLocal && dateStrCustLocal !== dateStr) arr.push(dateStrCustLocal);
      } catch(e) {console.error(e)}
    }
    if (this.myEntExpiresAfterDays) {
      if (this.myEntExpiresAfterDays <= 0) arr.push('[0 days]');
      else arr.push(`[${this.myEntExpiresAfterDays?.toFixed(3)} days]`);
    }
    return arr.join(' — ');
  }
  get myEntName() {
    let name = this.myEnt?.nameAll;
    if (!name) return '';
    if (this.myEntExpired) name += ' [EXPIRED]';
    return name;
  }

  /**
   * @return {PreferedEnts}
   */
  get preferedEnts() {
    if (!this.#preferedEnts) {
      this.#preferedEnts = new PreferedEnts(this.json.preferedEnts);
    }
    return this.#preferedEnts;
  }

  get sortd() { return this.json.sortd }

  get firstSeen() { return this.#creationDate }
  get lastSeen() { return this.#lastRefreshDate }
  get firstSeenStr() { return helper.dtToHumanStr(this.firstSeen) ?? '' }
  get lastSeenStr() { return helper.dtToHumanStr(this.lastSeen) ?? '' }

  getLastPurchAmountStr({withCurr = true, short = true} = {}) {
    let val = this.json.last_purch?.paid_amount;
    if (!val) return '-';
    if (withCurr && this.json.last_purch?.curr) {
      val += ' ' + this.json.last_purch?.curr;
    }
    if (!short) {
      const arr = [
        this.json.last_purch?.type ?? '',
        this.json.last_purch?.payplat ?? '',
      ];
      arr.filter(val => !val);
      if (arr.length > 0) val += ' (' + arr.join(' | ') + ')';
    }
    return val;
  }

  get deviceInfo() {
    if (!this._deviceInfo) this._deviceInfo = new CustDeviceInfo(this.json.dinfo);
    return this._deviceInfo;
  }
}

export class IndividualFixedPriceOffering {
  constructor(json) {
    this.update(json);
  }

  clone() {
    const item = new IndividualFixedPriceOffering({...(this.json ?? {})});
    const json = item.json;
    const extraOfferings = item.extraOfferings;
    if (json.extra_offerings && extraOfferings && extraOfferings.length > 0) {
      json.extra_offerings = [];
      for (const offering of extraOfferings) {
        json.extra_offerings.push(offering.clone().json);
      }
    } else {
      delete json.extra_offerings;
    }
    return item;
  }
  update(json) { this.json = {...(json ?? {})};}

  static withDefault(countryCode) {
    const iskg = countryCode === 'kg' || countryCode === 'KG';
    return new IndividualFixedPriceOffering({
      name: 'Individual',
      name_ru: 'Индивидуальный',
      ent: 'individual',
      fallback_ent: iskg ? 'min' : 'free',
      curr: iskg ? 'KGS' : 'USD',
      price_for_1_month: iskg ? 1000 : 100,
      has_disc_for_12_months: 1,
    });
  }

  get name() { return this.json.name }
  set name(val) { this.json.name = val }

  get nameRu() { return this.json.name_ru }
  set nameRu(val) { this.json.name_ru = val }

  get ent() { return this.json.ent }
  set ent(val) { this.json.ent = val }

  get fallbackEnt() { return this.json.fallback_ent }
  set fallbackEnt(val) { this.json.fallback_ent = val }

  get curr() { return this.json.curr }
  set curr(val) { this.json.curr = val }

  get priceFor1Month() { return this.json.price_for_1_month }
  set priceFor1Month(val) { this.json.price_for_1_month = parseFloat(val) ?? 0 }

  get hasDiscFor12Months() { return this.json.has_disc_for_12_months === 1 }
  set hasDiscFor12Months(val) { this.json.has_disc_for_12_months = val ? 1 : 0 }
  
  /**
   * @return {[IndividualFixedPriceOfferingItem]}
  */
  get extraOfferings() { return this.json.extra_offerings?.map(item => new IndividualFixedPriceOfferingItem(item)) ?? [] }
  set extraOfferings(val) { this.json.extra_offerings = val }
}

export class IndividualFixedPriceOfferingItem {
  constructor(json) {
    this.update(json);
  }

  clone() { return new IndividualFixedPriceOfferingItem({...(this.json ?? {})}) }
  update(json) { this.json = {...(json ?? {})};}

  static withDefault(countryCode) {
    const iskg = countryCode === 'kg' || countryCode === 'KG';
    return new IndividualFixedPriceOffering({
      id: 'ifpoi'+(Math.round(Math.random()*99999999)),
      name: 'Individual',
      name_ru: 'Индивидуальный',
      ent: 'individual',
      price_for_1_month: iskg ? 1000 : 100,
      has_disc_for_12_months: 1,
    });
  }

  get id() { return this.json.id }

  get name() { return this.json.name }
  set name(val) { this.json.name = val }

  get nameRu() { return this.json.name_ru }
  set nameRu(val) { this.json.name_ru = val }

  get ent() { return this.json.ent }
  set ent(val) { this.json.ent = val }

  get priceFor1Month() { return this.json.price_for_1_month }
  set priceFor1Month(val) { this.json.price_for_1_month = parseFloat(val) ?? 0 }

  get hasDiscFor12Months() { return this.json.has_disc_for_12_months === 1 }
  set hasDiscFor12Months(val) { this.json.has_disc_for_12_months = val ? 1 : 0 }
}

class PreferedEnts {
  #entids = [''];
  #ents = {};

  constructor(json) {
    this.json = json || {};
    this.#entids = null;
  }

  clone() { return new PreferedEnts(structuredClone(this.json)) }

  get entids() {
    if (!this.#entids) this.#entids = Object.keys(this.json);
    return this.#entids;
  }

  /**
   * 
   * @param {String} id 
   * @returns {Entitlement | null}
   */
  getEntWithId(id) {
    if (!this.#ents[id] && this.json[id]) {
      this.#ents[id] = new Entitlement(this.json[id]);
    }
    return this.#ents[id];
  }

  exists(id) {
    return this.getEntWithId(id) ? true : false;
  }

  add(ent) {
    if (!ent?.id) return;
    this.remove(ent.id);
    this.json[ent.id] = structuredClone(ent);
    return this;
  }

  remove(id) {
    if (!this.exists(id)) return;
    delete this.#ents[id];
    delete this.json[id];
    return this;
  }
}

const data = new Customers();
export default data;


