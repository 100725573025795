import { IconButton } from "@material-ui/core";
import { ArrowBack, ArrowForward, Autorenew, MoreHoriz } from "@material-ui/icons";
import { LoadingComp } from "./comps";

export function RefreshIconBtn({onRefresh = () => {}, busy = false, size = 'medium'}) {
  return (
    <IconButton size={size} aria-label="refresh" disabled={busy} onClick={onRefresh}>
      {busy ? <LoadingComp size="sm" /> : <Autorenew />}
    </IconButton>
  );
}

export function NextIconBtn({onClick = () => {}, disabled = false}) {
  return (
    <IconButton aria-label="next" disabled={disabled} onClick={onClick}>
      <ArrowForward />
    </IconButton>
  );
}

export function PrevIconBtn({onClick = () => {}, disabled = false}) {
  return (
    <IconButton aria-label="previous" disabled={disabled} onClick={onClick}>
      <ArrowBack />
    </IconButton>
  );
}


