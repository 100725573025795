import { useEffect, useRef, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { GeneralData } from "../../../data/general";
import * as model from "../../../data/report/payment/model";
import ds, { PEventTransformer } from "../../../data/report/payment/payment-events";
import * as helper from './../../../core/helper';

const purchTypes = [model.PurchType.all, model.PurchType.purchase, model.PurchType.renew];

export class AvgcheckChartDataTransformer extends PEventTransformer {
  /**
   * @param {model.ItemResultGroup} res
   */
  addResult(res) {
    const obj = {
      name: res.dtName,
      nameLong: res.dtNameLong,
    };
    purchTypes.forEach(purchType => {
      const nm = purchType.name;
      obj['all'+nm] =       this.#getVal(purchType, res, model.MonthType.all);
      obj['m1'+nm] =        this.#getVal(purchType, res, model.MonthType.month1);
      obj['m1_recur'+nm] =  this.#getVal(purchType, res, model.MonthType.month1Recurring);
      obj['m3'+nm] =        this.#getVal(purchType, res, model.MonthType.month3);
      obj['m6'+nm] =        this.#getVal(purchType, res, model.MonthType.month6);
      obj['m12'+nm] =       this.#getVal(purchType, res, model.MonthType.month12);
      obj['m1_total'+nm] =  this.#getVal(purchType, res, model.MonthType.single);
    });
    this.data.push(obj);
  }

  #getVal(purchType, res = new model.ItemResultGroup(), monthType = null) {
    const item = res.sumItem(purchType, monthType);
    const amount = item?.positive ?? 0;
    const count = item?.countPositive ?? 0;
    if (!amount || !count) return 0;
    return parseFloat((amount / count).toFixed(2));
  }
}

/**
 * 
 * @param {{loading: Boolean, src: PEventTransformer, currType: model.CurrType}} param0 
 * @returns 
 */
function AvgcheckChartView({loading, src, currType}) {
  const [data, setData] = useState([]);
  const [purchType, setPurchType] = useState(purchTypes[0]);
  
  useEffect(() => {
    if (src) {
      src.onUpdate = () => setData(src.data);
    }
    return () => src?.dispose();
  }, [src]);

  const curr = currType?.displayName;
  const nm = purchType.name;
  const colors = GeneralData.chartColors;

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <span disabled={loading} className="filterControls">
            <span>Average check {curr}</span>
            <span>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="purchType" size="sm">{purchType?.displayName}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {purchTypes.map(item => 
                    <Dropdown.Item key={item.name+'acv'} onClick={() => setPurchType(item)}>{item.displayName}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </span>
        </Card.Title>
      </Card.Body>
      <div style={styles.chart}>
        <ResponsiveContainer width="100%" aspect={2} maxHeight={240}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="1 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              formatter={(value, name, props) => [`${helper.finFormatAmount(value, {currency: curr})} - ${name}`]}
              labelFormatter={(label, props) => <small>{props[0]?.payload?.nameLong}</small>}
              />
            <Legend />
            <Line dataKey={'all'+nm} name="All" type="monotone" stroke={colors[0]} animationDuration={800} />
            <Line dataKey={'m1_recur'+nm} name="1 month recurring" type="monotone" stroke={colors[2]} animationDuration={800} />
            <Line dataKey={'m1'+nm} name="1 month" type="monotone" stroke={colors[1]} animationDuration={800} />
            <Line dataKey={'m3'+nm} name="3 months" type="monotone" stroke={colors[3]} animationDuration={800} />
            <Line dataKey={'m6'+nm} name="6 months" type="monotone" stroke={colors[4]} animationDuration={800} />
            <Line dataKey={'m12'+nm} name="12 months" type="monotone" stroke={colors[5]} animationDuration={800} />
            <Line dataKey={'m1_total'+nm} name="* 1m not recurring total" type="monotone" stroke={colors[6]} animationDuration={800} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

export default AvgcheckChartView;

const styles = {
  chart: {
    padding: 8,
  },
}



