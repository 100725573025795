import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrmbs } from "../comps/comps";
import { RefreshIconBtn } from "../comps/icon-btns";
import api from "./../api/api";

let logsCache = [];

function LogsPage() {
  const [names, setNames] = useState(logsCache ?? []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!names || names.length < 1) loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.inst.get('/bof/logs');
    logsCache = [...(resp.data ?? [])].reverse();
    setNames(logsCache);
    setLoading(false);
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <div>
          <Breadcrmbs
            items={[{to: -1, title: 'Settings'}]}
            currentTitle="Logs"
          />
        </div>
        <div>
          <RefreshIconBtn busy={loading} onRefresh={loadData}/>
        </div>
      </div>
      <div style={{padding: 8}}>
        {names.map(name =>
          <div key={name}>
            <Link to={`/logs/${name}`}>{name}</Link>
          </div>
        )}
      </div>
      <div>
        <RefreshIconBtn busy={loading} onRefresh={loadData}/>
      </div>
    </div>
  );
}

export default LogsPage;

export function LogsDetailPage() {
  const { name } = useParams();
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.inst.get('/bof/logs/' + name);
    const arr = (resp.data ?? '').split('\n').reverse();
    setData(arr.join('\n'));
    setLoading(false);
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <div>
          <Breadcrmbs
            items={[{to: -2, title: 'Settings'}, {to: -1, title: 'Logs'}]}
            currentTitle={name}
          />
        </div>
        <div>
          <RefreshIconBtn busy={loading} onRefresh={loadData}/>
        </div>
      </div>
      <div style={{padding: 8}}>
        <pre>
          {data}
        </pre>
      </div>
      <div>
        <RefreshIconBtn busy={loading} onRefresh={loadData}/>
      </div>
    </div>
  );
}


