import api from "./../api/api";
import entds from "./entitlements";
import custds, { Customer } from "./customers";

class Offerings {
  constructor() {
    this.data = [new OfferingsVo()];
    this.data.pop();
    this.byId = new Map();
    this.byId.set('dummy', new OfferingsVo());
    this.byId.clear();
    this.preferedWebOfferings = [new PreferedWebOfferings()];
    this.preferedWebOfferings.pop();
  }

  reset() {
    this.data = [];
  }

  /**
   * @return {OfferingsVo}
   */
  itemForId(id) {
    if (id?.startsWith('cust_')) {
      const custEmail = id.split('cust_').join('').split('/')[0];
      const offeringsId = id.split('/')[1];
      if (offeringsId === Customer.individualFixedPriceWebOfferingKey)
        return custds.itemForEmail(custEmail)?.individualFixedPriceWebOfferingConverted;
    }
    return this.byId.get(id);
  }

  async loadList() {
    if (entds.data.length < 1) {
      await entds.loadList();
    }

    try {
      let path = '/bof/offerings';
      const resp = await api.inst.get(path);
      this.data = [];
      resp.data?.forEach(element => {
        const item = new OfferingsVo(element);
        this.data.push(item);
        this.byId.set(item.id, item);
      });
      await this.loadPreferedWebOfferings();
    } catch(e) {}
    return this.data;
  }

  async loadPreferedWebOfferings() {
    try {
      let path = '/bof/offerings/web/prefered';
      const resp = await api.inst.get(path);
      this.preferedWebOfferings = [];
      const countries = Object.keys(resp.data);
      for (let i = 0; i < countries.length; i++) {
        const country = countries[i];
        const item = new PreferedWebOfferings(country, resp.data[country]);
        this.preferedWebOfferings.push(item);
      }
    } catch(e) {}
    return this.preferedWebOfferings;
  }
}

export class PreferedWebOfferings {
  constructor(country, offeringsId) {
    this.country = country;
    this.offeringsId = offeringsId;
  }
}

export class OfferingsVo {
  #_offerings = [];

  constructor(json) {
    this.update(json);
  }

  update(json) {
    this.json = json ?? {};
    this.#_offerings = this.json?.offerings?.map(json => new Offering(json)) ?? [];
  }

  get id() { return this.json.id }
  get type() { return this.json.type }
  get active() { return this.json.active === 1 || this.json.active === '1' || this.json.active === true }
  get activeStr() { return this.active ? 'YES' : 'NO' }

  get fallbackEntId() { return this.json.fallback_ent }
  get fallbackEnt() { return entds.itemForId(this.fallbackEntId) }

  get fallbackEntIdKg() { return this.json.fallback_ent_country_kg }
  get fallbackEntKg() { return entds.itemForId(this.fallbackEntIdKg) }

  /**
   * @return {[Offering]}
   */
  get offerings() { return this.#_offerings }
}

export class Offering {
  #_payopts = [];
  #_payoptsByMonth = {};

  constructor(json) {
    this.update(json);
  }
  
  update(json) {
    this.json = json ?? {};

    this.#_payopts = [];
    const payMonth = this.json?.pay_month ?? {};
    const months = Object.keys(payMonth);
    for (let i = 0; i < months.length; i++) {
      const month = months[i];
      const payopt = new OfferingPayOpt({...payMonth[month], month});
      this.#_payopts.push(payopt);
      this.#_payoptsByMonth[month] = payopt;
    }
  }

  get id() { return this.json.id }
  get active() { return this.json.active === 1 || this.json.active === '1' || this.json.active === true }
  get activeStr() { return this.active ? 'YES' : 'NO' }
  name(lang) { return this.json['name' + (lang ? `_${lang}` : '')] }

  get entId() { return this.json.ent }
  get ent() { return entds.itemForId(this.entId) }

  /**
   * @return {[OfferingPayOpt]}
   */
  get payopts() { return this.#_payopts }

  /**
   * @return {OfferingPayOpt}
   */
  payoptForMonth(month) { return this.#_payoptsByMonth[month+''] }
}

export class OfferingPayOpt {
  constructor(json) {
    this.update(json);
  }
  
  update(json) { this.json = json ?? {}; }

  get month() { return this.json.month }
  get price() { return this.json.price }
  get curr() { return this.json.curr }
  get recurring() { return this.json.recurring === 1 || this.json.recurring === '1' || this.json.recurring === true }
  get recurringStr() { return this.recurring ? 'YES' : 'NO' }
  get custSaves() { return this.json.cust_saves }

  get prodApple() { return this.json.prod_apple }
  get prodAndroid() { return this.json.prod_android }
  get prodWeb() { return this.json.prod_web }
}

const data = new Offerings();
export default data;


