import { useEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as countries from "country-list";
import { LoadingComp, Breadcrmbs } from "../../comps/comps";
import { NextIconBtn, PrevIconBtn, RefreshIconBtn } from "../../comps/icon-btns";
import ds from "./../../data/matomo/customers-matomo";
import * as helper from './../../core/helper';

const untilDts = [
  {id: 0, name: 'Today'},
  {id: 1, name: 'Last 2 days'},
  {id: 2, name: 'Last 3 days'},
  {id: 3, name: 'Last 4 days'},
  {id: 4, name: 'Last 5 days'},
  {id: 5, name: 'Last 6 days'},
  {id: 6, name: 'Last 7 days'},
  {id: 30, name: 'Last 30 days'},
];

const cache = {
  country: '_all_',
  lang: '_all_',
  untilId: untilDts[0].id,
}

function CustomersMatomoPage() {
  const navigation = useNavigate();
  const inited = useRef(false);
  const [data, setData] = useState(ds.data);
  const [loading, setLoading] = useState(false);
  const [untilId, setUntilId] = useState(cache.untilId);

  const [filterCountry, setFilterCountry] = useState(cache.country);
  const [countryList, setCountryList] = useState(['_all_']);

  const [filterLang, setFilterLang] = useState(cache.lang);
  const [langList, setLangList] = useState(['_all_']);

  useEffect(() => {
    if (!inited.current) {
      loadData({force: false});
    }
    inited.current = true;
  }, []);

  useEffect(() => {
    const newData = [];
    ds.data.forEach(item => {
      if (filterCountry !== '_all_' && item.activeInfo.countryCode !== filterCountry) return;
      if (filterLang !== '_all_' && item.activeInfo.langCode !== filterLang) return;
      newData.push(item);
    });
    setData(newData);

    cache.country = filterCountry;
    cache.lang = filterLang;
  }, [filterLang, filterCountry, langList, countryList]);

  const loadData = async ({force = true, _untilId} = {}) => {
    if (_untilId !== undefined) {
      setFilterCountry('_all_');
      setFilterLang('_all_');
    }

    const dates = [new Date()];
    _untilId = _untilId ?? untilId ?? 0;
    if (_untilId && _untilId > 0) {
      for (let i = 0; i < _untilId; i++) {
        dates.push(helper.date({date: new Date(), utc: false, addedDay: -(i+1)}));
      }
    }
    cache.untilId = _untilId;

    setLoading(true);
    if (force || (!data || data.length < 1)) await ds.load({dates: dates});
    setCountryList(Array.from(ds.countryCodes).sort());
    setLangList(Array.from(ds.langCodes).sort());
    setLoading(false);
  }

  // const handleSearch = async (e) => {
  //   e.preventDefault();
  //   if (loading) return;
  //   const email = e.target[0].value;
  //   navigation('/matomo/customers/email/' + email);
  // }

  if (loading) return <LoadingComp />;

  return (
    <div>
      <div disabled={loading} className="filterControls">
        {/* <span>
          <Form onSubmit={handleSearch}>
            <div className="form-item">
              <Form.Control size="sm" type="email" placeholder="email" disabled={loading} required />
            </div>
          </Form>
        </span> */}
        <span>
          <div className="form-item" style={{maxWidth: 180}}>
            <Form.Control
              disabled={loading}
              size="sm"
              as="select"
              value={filterCountry}
              onChange={e => {
                setFilterCountry(e.target.value);
              }}
            >
              <option value="_all_">All countries</option>
              {countryList.map(code =>
                <option key={code} value={code}>
                  {countries.getName(code)}
                </option>
              )}
            </Form.Control>
          </div>
        </span>
        <span>
          <div className="form-item" style={{maxWidth: 180}}>
            <Form.Control
              disabled={loading}
              size="sm"
              as="select"
              value={filterLang}
              onChange={e => {
                setFilterLang(e.target.value);
              }}
            >
              <option value="_all_">All Languages</option>
              {langList.map(code =>
                <option key={code} value={code}>
                  {helper.Languages.nameWithCode(code)}
                </option>
              )}
            </Form.Control>
          </div>
        </span>
        <span>
          <div className="form-item" style={{maxWidth: 180}}>
            <Form.Control
              disabled={loading}
              size="sm"
              as="select"
              value={untilId}
              onChange={e => {
                if (e.target.value === untilId) return;
                setUntilId(e.target.value);
                ds.reset();
                loadData({_untilId: e.target.value});
              }}
            >
              {untilDts.map(obj =>
                <option key={'untildt'+obj.id} value={obj.id}>
                  {obj.name}
                </option>
              )}
            </Form.Control>
          </div>
        </span>
        <span>
          <RefreshIconBtn busy={loading} onRefresh={() => {
            ds.totalReset();
            loadData();
          }}/>
        </span>
      </div>
      <Table striped bordered>
          <thead>
            <tr>
              <th>{data?.length || '#'}</th>
              <th>Email</th>
              <th>Language</th>
              <th>Country</th>
              <th>Type</th>
              <th>Days</th>
              <th>Device</th>
              <th>Last&nbsp;action</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item, index) =>
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td><Link to={`/customers/email/${item.bus.email}`}>{item.bus.email}</Link></td>
                <td>{item.activeInfo.language}</td>
                <td>{item.activeInfo.country}</td>
                <td>{item.bus.busType}</td>
                <td>{item.buses.length}</td>
                <td>{item.bus.visDevice}</td>
                <td>{item.activeInfo.lastActionHuman}</td>
              </tr>
            )}
          </tbody>
        </Table>
    </div>
  );
}

export default CustomersMatomoPage;



